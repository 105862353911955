import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7b0eb9dc"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "common-table"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_table_column = _resolveComponent("el-table-column");
  const _component_el_table = _resolveComponent("el-table");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_table, {
    ref: "multipleTableRef",
    "show-header": $props.showHeader,
    border: $props.border,
    data: $props.tableData,
    "max-height": $props.tableHeight,
    style: {
      "width": "100%"
    },
    onSelectionChange: $setup.handleSelectionChange,
    "row-class-name": _ctx.$attrs.tableRowClassName
  }, {
    default: _withCtx(() => [$props.showSelection ? (_openBlock(), _createBlock(_component_el_table_column, {
      key: 0,
      "class-name": _ctx.$attrs.className,
      type: "selection",
      width: "55",
      fixed: "left"
    }, null, 8, ["class-name"])) : _createCommentVNode("", true), _renderSlot(_ctx.$slots, "default", {}, undefined, true)]),
    _: 3
  }, 8, ["show-header", "border", "data", "max-height", "onSelectionChange", "row-class-name"])]);
}