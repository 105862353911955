export default {
  login: {
    "Welcome_to_TC_system": "Welcome to TC system",
    "Login_account": "Login account",
    "Please_enter_your_login_account": "Please enter your login account",
    "Login_password": "Login password",
    "Please_enter_your_login_password": "Please enter your login password",
    "Remember_the_account_password": "Remember the account password",
    "Enterprise_wechat_login": "Enterprise wechat login",
    "Log_in": "Log in",
    "logining": "logining",
    "Login_success": "Login success",
    "Login_failure": "Login failure",
    "exit": "exit",
    "exiting": "exiting",
    "Exit_successfully": "Exit successfully",
    "Exit_failure": "Exit failure",
    "Welcome_to_login_TC_system": "Welcome to login TC system",
    "account": "account",
    "Password": "Password",
    "Please_enter_login_account": "Please enter login account",
    "Please_enter_login_password": "Please enter login password",
    "Log_in_Wecom": "Log in Wecom",
    "It_is_on_the_access_road": "It is on the access road",
    "Access_error": "Access error",
  },
  menu: {
    "WarehouseData": "warehouse data",
    "operate_time": "operate time",
    "View_details": "View details",
    "Warehousing_management": "Warehousing",
    "Out_warehouse_management": "Out warehouse",
    "Order_management": "Order management",
    "Pallet_management": "Pallet management",
    "Abnormal_data": "Abnormal data",
    "Warehousing_anomaly": "Warehousing anomaly",
    "Outgoing_exception": "Outgoing exception",
    "Operation_exception": "Operation exception",
    "Basic_setup": "Basic setup",
    "Vehicle_management": "Vehicle management",
    "Order_list": "Order list",
    "warehouse_data": "warehouse data",
    "classification_data": "classification data",
    "classification_details": "classification details",
    "pela_details": "pela details",
    "cargo_entry_management": "cargo entry management",
    "cargo_entry_details": "cargo entry details",
    "Shipment_details": "Shipment details",
    "load_output_administration": "load output administration",
    "load_output_details": "load output details",
    "order_administration": "order administration",
    "order_details": "order details",
    "abnormal_load_input_situation": "abnormal load input situation",
    "abnormal_situation_details": "abnormal situation details",
    "load_output_abnormal_situation": "load output abnormal situation",
    "abnormal_driving_situation": "abnormal driving situation",
    "container_administration": "container administration",
    "container_details": "container details",
    "location_management": "location management",
    "classification_management": "classification management",
    "platform_management": "platform management",
    "car_management": "car management",
    "Mercado_Libre_Reservation": "Mercado Libre Reservation",
    "Reservation_status": "Reservation status",
    "Check_the_shipment_number": "Check the shipment number",
    "Warehouse_details": "Warehouse details",
    "Enterprise_architecture_management": "Enterprise architecture management",
    "System_setting": "System setting",
    "Department_setting": "Department setting",
    "Employee_account_management": "Employee account management",
    "Role_rights_management": "Role rights management",
    "Role_setting": "Role setting",
    "Authority_management": "Authority management",
    "Work_order_management": "Work order management",
    "Add_new_work_order": "Add new work order",
    "Work_order_details": "Work order details",
    "Customer_service_Settings": "Customer service Settings",
    "Work_order_type": "Work order type",
    "Problem_type": "Problem type",
    "Drafts": "Drafts",
    "check": "check",
    "add": "add",
    "intercept": "intercept",
    "updateFiles": "updateFiles",
    "delete": "delete",
    "cancel": "cancel",
    "edit": "edit",
    "download": "download",
    "storage_management": "storage management",
    "No_owner_claimed_the_goods": "No owner claimed the goods",
    "return_claim": "return claim",
    "loadingLot": "loading lot",
    "lotDetail": "lot detail",
    "return_goods": "Returns/USA_Mexico",
    "returnGoods": "Returns/USA_Mexico",
    "warehouse": "Warehousing",
    "outwarehouse": "Out warehouse",
    "refuseOutwarehouse": "Refuse to return to warehouse",
    "transfer": "Transfer",
    "inventory": "Inventory",
    "pickGoods": "Pick goods",
    "listGoods": 'Cargo handling',
    "outerSheet": "Carton label",
    "productSheet": "Product label",
    "findGoods": "Check the goods",
    "claimGoods": "Cargo claim",
    "truckLoading": "entrucking",
    "picsUpload": "Picture upload",
    "changeLabel": "change label",
    "checkZone": "Check the location",
    "Update": "Update",
    "pda": "pda",
    "areaScan": "area scan",
    "goods_ageing": "goods ageing",
    "zoneInventory": "zone inventory",
    "workSheet": "Work order processing",
    "storage_list": "storage list",
    "first_route_order": "first route order",
    "transfer_warehouse_order": "transfer warehouse order",
    "order_detail": "order detail",
    "transferWarehouseProcess": "transfer warehouse process",
    "transferToWarehouse": "transfer to warehouse",
    "drop_shipping_outwarehouse": "drop shipping outwarehouse",
    "drop_shipping_outwarehouse_detail": "drop shipping outwarehouse detail",
    "picking_task": "picking task",
    "picking_list": "picking list",
    "picking_outwarehouse": "picking outwarehouse",
    "task_detail": "task detail",
    "warehousing_manage": "warehousing manage",
    "container_manage": "container manage",
    "location_manage": "location manage",
    "distribution_manage": "distribution manage",
    "shelves_manage": "shelves manage",
    "pull_on_shelves_list": "pull on shelves list",
    "pull_off_shelves_list": "pull off shelves list",
    "warehouse_manage": "warehouse manage",
    "goods_stock": "goods stock",
    "sku_stock": "sku stock",

    "inventory_adjustment": "inventory adjustment",
    "container_detail": "container detail",
    "product_information": "product information",
    "order_manage": "order manage",
    "all_order": "all order",
    "shein_order": "SHEIN order",
    "warehouse_detail": "warehouse detail",
    "subarea_detail": "subarea detail",
    "location_detail": "location detail",
    "goods_detail": "goods detail",

    "general_situation": "general situation",
    "batchOutWarehouse": "batch out warehouse",
    "UpdateTheOutboundTime": "Update the outbound time",
    "DestroyGoods": "Destroy goods",
    "system_setting": "System Settings",
    "courier_freight_manage": "Courier Freight Management",

    "setupOutaccount": "Set up outgoing account",
    "shipment": "Shipment number information",
    "changeShipment": "Change the shipment number for re-labeling",
    "batchDistributionWorkSheet": "Batch distribution worksheet"
  },
  selectPart: {
    "Operation_center": "Operation",
    "order_center": "Order",
    "Enterprise_center": "Enterprise center",
    "Service_center": 'Service center',
    "pda": "pda",
    "Inventory_center": "Inventory center",
    "distribution_center": "distribution center"
  },
  warehouseData: {
    "Goods_to_be_listed": "Goods to be listed",
    "Container_code_to_be_listed": "Container code to be listed",
    "Current_pieces": "Current pieces",
    "Current_pieces_of_order/ticket": "Current pieces of order/ticket",
    "Total_weight": "Total weight",
    "Total_weight/volume": "Total weight/volume",
    "Pending_arrival_orders_at_the_warehouse": "Pending arrival orders at the warehouse",
    "Orders_pending_handling": "Orders pending handling",
    "abnormal_orders": "abnormal orders",
    "orders_that_have_been_held_for_a_long_time": "orders that have been held for a long time",
    "Total_pieces": "Total pieces",
    "Total_volume": "Total volume",
    "Total_order/ticket": "Total order/ticket",
    "Day": "Day",
    "Month": "Month",
    "Year": "Year",
    "cargo_intake_today/piece": "cargo intake today/piece",
    "Morning": "Morning",
    "Afternoon": "Afternoon",
    "cargo_departure_today/piece": "cargo departure today/piece",
    "Handling_today/piece": "Handling today/piece",
    "Classification_of_warehouse_area": "Classification of warehouse area",
    "Import_data": "Import data",
    "reservation_space_is_less_than": "reservation space is less than",
    "cargo_entry_worker": "cargo entry worker",
    "Time_of_warehousing": "Time of warehousing",
    "Warehouse": "Warehouse",
    "classification_area": "classification area",
    "pallet_number": "pallet number",
    "deposit_time": "deposit time",
    "secondary_shipment_number": "secondary shipment number",
    "secondary_shipping/secondary_outer_carton_label": "secondary shipping/secondary outer carton label",
    "Pieces": "Pieces",
    "Weight": "Weight",
    "Number_of_shipment": "Number of shipment",
    "Number_of_entering_warehouse": "Number of entering warehouse",
    "Number_of_products/tag": "Number of products/tag",
    "choose_classification_area": "choose classification area",
    "filter": "filter",
    "Reset": "Reset",
    "Client_name": "Client name",
    "Salesman": "Salesman",
    "deposit_time/day": "deposit time/day",
    "handling": "handling",
    "Check": "Check",
    "Please_enter_content": "Please enter content",
    "No_region_storage_information_was_entered": "No region storage information was entered",
    "Storage_space_less_than": "Storage space less than",
    "Search_results": "Search results",
    "Total": "Total",
    "Old_shipment_number": "Old shipment number",
    "Old_sub-shipment_number/shipping_mark": "Old sub-shipment number/shipping mark",
    "change_order_number": "change order number",
    "region_name": "region name",
    "status": "status",
    "Update_time": "Update time",
    "volume": "volume",
    "Data_export": "Data export",
    "Already_listed": "Already listed",
    "Taken_down": "Taken down"
  },
  warehousing: {
    "delivery_number": "delivery number",
    "cargo_entry_number": "cargo entry number",
    "shipping/outer_box_label": "shipping/outer box label",
    "state": "state",
    "Container_pick_up_date": "Container pick up date",
    "cargo_entry_time": "cargo entry time",
    "import_data": "import data",
    "export_data": "export data",
    "Container_number": "Container number",
    "SO_number": "SO number",
    "filter": "filter",
    "reboot": "reboot",
    "pick_up_order_number/container_number": "pick up order number/container number",
    "type_of_transport": "type of transport",
    "related_shipping_quantity/pcs": "related shipping quantity/pcs",
    "total_pieces/piece": "total pieces/piece",
    "total_volume/cubic_meters": "total volume/cubic meters",
    "total_weight/kg": "total weight/kg",
    "warehouse_receipt_amount/piece": "warehouse receipt amount/piece",
    "review": "review",
    "secondary_shipment_number": "secondary shipment number",
    "secondary_shipping/secondary_outer_carton_label": "secondary shipping/secondary outer carton label",
    "product_parts/parts": "product parts/parts",
    "volume/cubic_meters": "volume/cubic meters",
    "weight/kg": "weight/kg",
    "have_been_entered": "have been entered",
    "total_pieces": "total pieces",
    "total_weight": "total weight",
    "total_volume": "total volume",
    "cargo_entry_parts": "cargo entry parts",
    "pieces_without_charge_entry": "pieces without charge entry",
    "driving": "driving",
    "print_income_sheet": "print income sheet",
    "export_income_sheet": "export income sheet",
    "warehousing": "warehousing",
    "Failed_to_get_warehousing_list": "Failed to get warehousing list",
    "Transport_type": "Transport type",
    "Failed_to_get_warehousing_details": "Failed to get warehousing details",
    "Failed_to_get_waybill_details": "Failed to get waybill details",
    "shipping": "shipping",
    "secondary_shipping": "secondary shipping",
    "location_code/container_code": "location code/container code",
    "all": "all",
    "warehoused": "warehoused",
    "exporting": "exporting",
    "Export_successfully": "Export successfully",
    "printing": "printing",
    "Print_successfully": "Print successfully",
    "Print_unsuccessfully": "Print unsuccessfully",
    "Company_logo": "Company logo",
    "Export_unsuccessfully": "Export unsuccessfully"
  },
  outwarehouse: {
    "delivery_number": "delivery number",
    "load_output_number": "load output number",
    "shipping/outer_box_label": "shipping/outer box label",
    "state": "state",
    "creation_date": "creation date",
    "cargo_departure_time": "cargo departure time",
    "related_shipping_quantity/pcs": "related shipping quantity/pcs",
    "scan_quantity/pieces": "scan quantity/pieces",
    "person_responsible_for_shipping": "person responsible for shipping",
    "shipping_car_number": "shipping car number",
    "secondary_shipment_number": "secondary shipment number",
    "secondary_shipping/secondary_outer_carton_label": "secondary shipping/secondary outer carton label",
    "successful_scan_parts": "successful scan parts",
    "parts_without_scanning": "parts without scanning",
    "download_authorization": "download authorization",
    "print_output_sheet": "print output sheet",
    "export_output_sheet": "export output sheet",
    "Create_delivery_list": "Create delivery list",
    "title": "title",
    "Total": "Total",
    "related_shipping_quantity": "related shipping quantity",
    "successful_number": "successful number",
    "reject_number": "reject number",
    "intercept_number": "intercept number",
    "creator": "creator",
    "address": "address",
    "Create_time": "Create time",
    "Delivery_time": "Delivery time",
    "remark": "remark",
    "driving": "driving",
    "view": "view",
    "delete": "delete",
    "Are_you_sure_to_delete": "Are you sure to delete",
    "confirm": "confirm",
    "cancel": "cancel",
    "waiting_for_out_warehouse": "waiting for out warehouse",
    "warehousing": "warehousing",
    "warehoused": "warehoused",
    "out_warehouse_abnormally": "out warehouse abnormally",
    "deleting": "deleting",
    "Successfully_delete": "Successfully delete",
    "Delete_unsuccessfully": "Delete unsuccessfully",
    "Failed_to_get_out_warehouse_list": "Failed to get out warehouse list",
    "Attached_file": "Attached file",
    "Only_support": "Only support",
    "format": "format",
    "upload": "upload",
    "save": "save",
    "saving": "saving",
    "Save_successfully": "Save successfully",
    "Save_unsuccessfully": "Save unsuccessfully",
    "Intercepted_number_of_out_warehouse": "Intercepted number of out warehouse",
    "Scan_list": "Scan list",
    "batch_out_warehouse": "batch out warehouse",
    "destroy_goods": "destroy goods",
    "out_warehouse_intercept": "out warehouse intercept",
    "Are_you_sure_to_out_warehouse?": "Are you sure to out warehouse?",
    "File_list": "File list",
    "batch_download": "batch download",
    "take_a_photo_and_send_back": "take a photo and send back",
    "Download": "Download",
    "close": "close",
    "Reject_list": "Reject list",
    "reject_reason": "reject reason",
    "filter": "filter",
    "reset": "reset",
    "edit": "edit",
    "Download_attachment": "Download attachment",
    "Update_attachment": "Update attachment",
    "Export_the_reject_list": "Export the reject list",
    "All": "All",
    "Scanned": "Scanned",
    "To_be_scanned": "To be scanned",
    "rejection": "rejection",
    "Failed_to_get_out_warehouse_details": "Failed to get out warehouse details",
    "Please_check_first": "Please check first",
    "Batch_delivery_successfully": "Batch delivery successfully",
    "Batch_out_warehouse_unsuccessfully": "Batch out warehouse unsuccessfully",
    "Failed_to_get_fileList": "Failed to get fileList",
    "File_is_empty": "File is empty",
    "Download_successfully": "Download successfully",
    "Download_unsuccessfully": "Download unsuccessfully",
    "Failed_to_get_scan_list": "Failed to get scan list",
    "The_attached_file_is_empty": "The attached file is empty",
    "exporting": "exporting",
    "Export_successfully": "Export successfully",
    "Export_unsuccessfully": "Export unsuccessfully",
    "Print_unsuccessfully": "Print unsuccessfully",
    "printing": "printing",
    "Print_successfully": "Print successfully",
    "intercept_reason": "intercept reason",
    "intercepting": "intercepting",
    "Intercept_successfully": "Intercept successfully",
    "Intercept_unsuccessfully": "Intercept unsuccessfully",
    "Edit_reject_reason": "Edit reject reason",
    "editing": "editing",
    "Edit_successfully": "Edit successfully",
    "Edit_failure": "Edit failure",
    "Please_upload_the_attached_file": "Please upload the attached file",
    "updating": "updating",
    "Update_successfully": "Update successfully",
    "Export_warehouse_orders_in_batches": "Export warehouse orders in batches",
    "Number_of_pieces_to_be_issued": "Number of pieces to be issued",
    "Platform": "Platform",
    "Export_excel_data_in_batches": "Export excel data in batches",
    "Export_the_pdf_of_the_swap": "Export the pdf of the swap",
    "Change_label_list": "Change label list",
    "Change_label_information_table": "Change label information table",
    "Error_table_of_label_change_information": "Error table of label change information",
    "List_of_unchanged_labels": "List of unchanged labels",
    "Storage_location_area": "Storage location area",
    "change_label_status": "change label status",
    "operator": "operator",
    "Error_cause": "Error cause",
    "Estimated_weight": "Estimated weight",
    "Estimated_volume": "Estimated volume",
    "abnormal_remark": "abnormal remark",
    "Number_of_items_that_should_be_shipped_out": "Number of items that should be shipped out",
    "Number_of_items_that_have_been_shipped_out": "Number of items that have been shipped out",
    "Number_of_items_that_have_not_been_shipped_out": "Number of items that have not been shipped out",
    "Number_of_intercepted_items": "Number of intercepted items",
    "Number_of_rejected_items": "Number of rejected items",
    "Number_of_items_that_should_have_outer_box_labels_changed": "Number of items that should have outer box labels changed",
    "Number_of_items_that_have_not_had_outer_box_labels_changed": "Number of items that have not had outer box labels changed",
    "Batch_processing_abnormal_child_orders": "Batch Processing of Abnormal Child Orders",
    "Appendix_to_authorization": "Appendix to Authorization",
    "Changing_attachment": "Attachment for Label Change",
    "Edit_Excel_Content": "Edit Excel Content",
    "Multiple_separated_by_Chinese_commas,_for_example:": "Multiple separated by Chinese commas, for example:",
    "Old_shipment_number_information": "Old Shipment Number Information",
    "Please_enter_remarks": "Please enter remarks",
    "Update_failure": "Update Failure",
    "Please_wait_until_the_file_is_uploaded": "Please wait until the file is uploaded",
    "Edit_SKU": "Edit SKU",
    "replace_SKU_or_not": "Replace SKU or not",
    "Change_total_quantity": "Change total quantity",
    "Old_SKU": "Old SKU",
    "New_SKU": "New SKU",
    "Update_total_quantity": "Update total quantity",
    "Add": "Add",
    "Confirm": "Confirm",
    "change": "Replace",
    "Do_not_replace": "Do not replace",
    "Please_Enter_Old_SKU": "Please enter old SKU",
    "Please_Enter_New_SKU": "Please enter new SKU",
    "Please_Enter_Total_Replacement_Quantity": "Please enter total replacement quantity",
    "Total_Replacement_Quantity_Cannot_Be_Less_Than_0": "Total replacement quantity cannot be less than 0",
    "The_List_Cannot_Be_Empty": "The list cannot be empty",
    "Please_Enter_Quantity": "Please enter quantity",
    "Quantity_Cannot_Be_Less_Than_0": "Quantity cannot be less than 0",
    "quantity_of_SKU_replacements": "quantity of SKU replacements",

    "Suspend_the_responsible_party_from_the_warehouse": "Suspend the responsible party's access to the warehouse",
    "Delivery_omission": "Missed delivery to warehouse",
    "Cancel_intercept": "Cancel interception",
    "Cancel_delivery_omission": "Cancel missed delivery",
    "client": "Client",
    "staff": "Staff",
    "reason": "Reason",
    "Cancel_intercept_processing": "Cancel interception in process",
    "Cancel_intercept_successfully": "Cancel interception successful",
    "Failed_to_cancel_intercept": "Failed to cancel interception",
    "Delivery_missing_processing": "Missed delivery to warehouse being processed",
    "Delivery_of_missing_processing_completed": "Missed delivery to warehouse processing completed",
    "Failed_to_send_warehouse_missing_processing": "Failed to process missed delivery to warehouse",
    "Cancel_delivery_missing": "Canceling missed delivery to warehouse",
    "Cancel_delivery_missing_complete": "Canceling missed delivery to warehouse completed",
    "Failed_to_cancel_delivery_omission": "Failed to cancel missed delivery",
    "Setting_an_account_failed": "Failed to set up the charge off account",
    "Set_the_account_successfully": "Successfully set up the charge off account",
    "Change_the_shipment_number": "Change the shipment number for re-labeling",
    "Shipment_number_information": "Shipment number information",
    "Charge_Off": "Charge Off",
    "Set_up_an_account": "Set Up a Charge Off Account",
    "Number_of_delivery_pieces": "Number of delivery pieces",
    "Not_Set": "Not Set",
    "Outstanding_account": "Outstanding Account (not yet charged off)",
    "Out_of_account": "Account Closed/Settled (already charged off)",
    "Check_SKU": "Check SKU",
    "Change_mark_or_not": "Replace Label or Not",
    "Total_number_of_label_changes": "Total Number of Label Changes",
    "Actual_number_of_label_changes": "Actual Number of Label Changes",
    "Number_of_repeated_label_changes": "Number of Repeated Label Changes",
    "old_sku": "Old SKU",
    "new_sku": "New SKU",
    "allNum": "Total Quantity",
    "Label_the_outer_box_repeatedly": "Repeatedly Label Outer Box",
    "Cancel_the_repeated_labeling_of_the_outer_box_label": "Cancel Repeated Labeling of Outer Box",
    "Whether_to_set_repeat_replacement": "Whether to Set Repeated Replacement",
    "The_outer_box_label_does_not_actually_change_the_label": "The outer box label does not actually change the product label",
    "Cancel_the_outer_box_label_actually_do_not_change_the_label": "Cancel the setting that the outer box label does not actually change the product label",
    "Set_the_actual_SKU_replacement_information": "Set the actual SKU replacement information",
    "If_the_label_has_been_changed_before,_set_the_actual_number_of_label_changes": "If the label has been changed before, please set the actual number of labels changed.",
    "Label_change_repeatedly": "Repeated label changing",
    "Repeated_switching": "Repeated switching of labels",
    "No_repeated_label_change": "No repeated label changing",
  },
  order: {
    "exchange_number": "exchange number",
    "cargo_departure_time": "cargo departure time",
    "cargo_exit_hold": "cargo exit hold",
    "charge_output_state": "charge output state",
    "type_of_transport": "type of transport",
    "pick_up_order_number/container_number": "pick up order number/container number",
    "shipping_time": "shipping time",
    "status_of_receiving_products": "status of receiving products",
    "cargo_access_code": "cargo access code",
    "load_exit_code": "load exit code",
    "time_to_remove_containers": "time to remove containers",
    "cargo_entry_time": "cargo entry time",
    "warehouse_management": "warehouse management",
    "old_reserve_area": "old reserve area",
    "reserve_area_now": "reserve area now",
    "secondary_shipment_number": "secondary shipment number",
    "new_secondary_shipping_number": "new secondary shipping number",
    "shipping_new/label": "shipping new/label",
    "length": "length",
    "width": "width",
    "height": "height",
    "export_sheet": "export sheet",
    "export_outer_box_label": "export outer box label",
    "export_corresponding_secondary_shipping_sheet": "export corresponding secondary shipping sheet",
    "export_corresponding_secondary_shipment/outer_box_label": "export corresponding secondary shipment/outer box label",
    "delivery_number": "delivery number",
    "shipping/outer_box_label": "shipping/outer box label",
    "export_data": "export data",
    "filter": "filter",
    "reboot": "reboot",
    "total_pieces/piece": "total pieces/piece",
    "total_volume/cubic_meters": "total volume/cubic meters",
    "total_weight/kg": "total weight/kg",
    "state": "state",
    "handling": "handling",
    "Check": "Check",
    "volume/cubic_meters": "volume/cubic meters",
    "weight/kg": "weight/kg",
    "total_pieces": "total pieces",
    "total_weight": "total weight",
    "total_volume": "total volume",
    "piece": "piece",
    "Company_logo": "Company logo",
    "Cargo_status": "Cargo status"
  },
  container: {
    "Print_partition_code": "Print partition code",
    "add_new_container": "add new container",
    "add": "add",
    "delivery_number": "delivery number",
    "container_code": "container code",
    "secondary_shipping/secondary_outer_carton_label": "secondary shipping/secondary outer carton label",
    "volume": "volume",
    "edit": "edit",
    "delete": "delete",
    "close": "close",
    "discharge": "discharge",
    "Cancel": "Cancel",
    "confirm_on_delete_container": "confirm on delete container",
    "edit_container": "edit container",
    "container_name": "container name",
    "save": "save",
    "export_data": "export data",
    "filter": "filter",
    "reboot": "reboot",
    "total_pieces": "total pieces",
    "related_shipping_quantity/pcs": "related shipping quantity/pcs",
    "secondary_shipment_number": "secondary shipment number",
    "cargo_access_code": "cargo access code",
    "piece": "piece",
    "print_container_code": "Print container code",
    "select_all": "select all",
    "Container_log": "container log",
    "batch_delete": "batch delete",
    "Transfer_record": "Transfer record",
    "Large_area_code": "Large area code",
    "pre_transfer": "pre-transfer",
    "post_transfer": "post-transfer",
  },
  abnormalData: {
    "delivery_number": "delivery number",
    "driving_type": "driving type",
    "cargo_entry_time": "cargo entry time",
    "shipping/outer_box_label": "shipping/outer box label",
    "Operating_deadline": "Operating deadline",
    "pieces_uploaded_without_scanning": "pieces uploaded without scanning",
    "paste_label": "paste label",
    "customers": "customers",
    "Salesman": "Salesman",
    "secondary_shipment_number": "secondary shipment number",
    "product_label": "product label",
    "load_output_number": "load output number",
    "cargo_departure_time": "cargo departure time",
    "time_to_remove_containers": "time to remove containers",
    "related_shipping_quantity/pcs": "related shipping quantity/pcs",
    "Warehouse_output_quantity/pieces": "Warehouse output quantity/pieces",
    "amount_of_strange": "amount of strange",
    "successful_scan_parts": "successful scan parts",
    "parts_without_scanning": "parts without scanning",
    "person_responsible_for_shipping": "person responsible for shipping",
    "shipping_car_number": "shipping car number",
    "secondary_shipping/secondary_outer_carton_label": "secondary shipping/secondary outer carton label",
    "quantity_of_products/pieces": "quantity of products/pieces",
    "cargo_entry_number": "cargo entry number",
    "warehouse_receipt_amount/piece": "warehouse receipt amount/piece",
    "pieces_of_strange": "pieces of strange",
    "pieces_without_charge_entry": "pieces without charge entry",
    "type_of_transport": "type of transport",
    "pick_up_order_number/container_number": "pick up order number/container number",
    "Data_export": "Data export",
    "filter": "filter",
    "reset": "reset",
    "piece": "piece",
    "Total_volume": "Total volume",
    "Total_weight": "Total weight",
    "driving": "driving",
    "view": "view",
    "out_warehouse_number": "out warehouse number",
    "title": "title",
    "status": "status",
    "Total": "Total",
    "reject_number": "reject number",
    "intercept_number": "intercept number",
    "creator": "creator",
    "address": "address",
    "create_time": "create time",
    "delivery_time": "delivery time",
    "remark": "remark",
    "delete": "delete",
    "Are_you_sure_to_delete": "Are you sure to delete",
    "cancel": "cancel",
    "waiting_for_out_warehouse": "waiting for out warehouse",
    "warehousing": "warehousing",
    "warehoused": "warehoused",
    "out_warehouse_abnormally": "out warehouse abnormally",
    "deleting": "deleting",
    "Successfully_deleted": "Successfully deleted",
    "Failed_to_get_out_warehouse_list": "Failed to get out warehouse list",
    "Deletion_failure": "Deletion failure",
    "Scan_list": "Scan list",
    "batch_delivery": "batch delivery",
    "out_warehouse_intercept": "out warehouse intercept",
    "Are_you_sure_to_out_warehouse?": "Are you sure to out warehouse?",
    "File_list": "File list",
    "batch_download": "batch download",
    "take_a_photo_and_send_back": "take a photo and send back",
    "download": "download",
    "close": "close",
    "Reject_list": "Reject list",
    "download_attachment": "download attachment",
    "edit": "edit",
    "update_attachment": "update attachment",
    "print_out_warehouse_list": "print out warehouse list",
    "export_out_warehouse_list": "export out warehouse list",
    "Export_the_reject_list": "Export the reject list",
    "all": "all",
    "Scanned": "Scanned",
    "To_be_scanned": "To be scanned",
    "rejection": "rejection",
    "Failed_to_get_out_warehouse_details": "Failed to get out warehouse details",
    "Failed_to_get_scan_list": "Failed to get scan list",
    "Please_check_first": "Please check first",
    "Batch_delivery_successfully": "Batch delivery successfully",
    "Batch_out_warehouse_unsuccessfully": "Batch out warehouse unsuccessfully",
    "Failed_to_get_fileList": "Failed to get fileList",
    "File_is_empty": "File is empty",
    "Download_unsuccessfully": "Download unsuccessfully",
    "Download_successfully": "Download successfully",
    "The_attached_file_is_empty": "The attached file is empty",
    "exporting": "exporting",
    "Export_successfully": "Export successfully",
    "Export_unsuccessfully": "Export unsuccessfully",
    "intercept_reason": "intercept reason",
    "intercepting": "intercepting",
    "Intercept_successfully": "Intercept successfully",
    "Edit_reject_reason": "Edit reject reason",
    "reject_reason": "reject reason",
    "editing": "editing",
    "Edit_successfully": "Edit successfully",
    "Edit_failure": "Edit failure",
    "Update_attachment": "Update attachment",
    "Please_upload_the_attached_file": "Please upload the attached file",
    "Only_xls,_xlsx,_PDF_files_are_supported": "Only xls, xlsx, PDF files are supported",
    "updating": "updating",
    "Update_successfully": "Update successfully",
    "Intercept_unsuccessfully": "Intercept unsuccessfully",
    "Failed_to_get_warehousing_list": "Failed to get warehousing list",
    "warehoused_number": "warehoused number",
    "volume": "volume",
    "weight": "weight",
    "Failed_to_get_warehousing_details": "Failed to get warehousing details",
    "confirm": "confirm",
    "save": "save",
    "Intercepted_number_of_out_warehouse": "Intercepted number of out warehouse",
    "Warehouse_area": "Warehouse area",
    "uploader": "uploader",
    "Upload_time": "Upload time",
    "picture": "picture",
    "TC_sub-order_number": "TC sub-order number",
    "secondary_shipping": "secondary shipping",
    "modifier": "modifier",
    "modifier_time": "modifier time",
    "edit_order_number": "edit order number",
    "please_enter_the_sub-order_number": "please enter the sub-order number",
    "please_enter_the_secondary_shipping": "please enter the secondary shipping",


  },
  baseSetting: {
    "add": "add",
    "brand": "brand",
    "boss/responsible_person": "boss/responsible person",
    "administrator": "administrator",
    "edit": "edit",
    "Delete": "Delete",
    "Sure_to_delete_car_information?": "Sure to delete car information?",
    "Confirm": "Confirm",
    "Cancel": "Cancel",
    "add_car_info": "add car info",
    "car_number": "car number",
    "Car_type": "Car type",
    "Purchased_time": "Purchased time",
    "all_people/everyone": "all people/everyone",
    "regulator/administrator": "regulator/administrator",
    "save": "save",
    "edit_car_info": "edit car info",
    "contact": "contact",
    "phone_contact": "phone contact",
    "detailed_address": "detailed address",
    "add_classification_area": "add classification area",
    "secondary_sorting_quantity/pieces": "secondary sorting quantity/pieces",
    "number_of_pallets/pieces": "number of pallets/pieces",
    "maximum_capacity/cubic_meters": "maximum capacity/cubic meters",
    "sure_to_delete_area_F?": "sure to delete area F?",
    "classification_name": "classification name",
    "maximum_capacity": "maximum capacity",
    "edit_warehouse_information": "edit warehouse information",
    "warehouse_name": "warehouse name",
    "secondary_classification_area": "secondary classification area",
    "add_secondary_sort_area": "add secondary sort area",
    "location_code": "location code",
    "capacity_now": "capacity now",
    "Carboard": "Carboard",
    "Add_carboard": "Add carboard",
    "Carboard_code": "Carboard code",
    "Edit_carboard": "Edit carboard",
    "Carboard_name": "Carboard name",
    "edit_secondary_sort_area": "edit secondary sort area",
    "secondary_sort_area_name": "secondary sort area name",
    "close": "close",
    "download": "download",
    "Total_number_of_pieces": "Total number of pieces",
    "shipping": "shipping",
    "delivery_number": "delivery number",
    "secondary_shipment_number": "secondary shipment number",
    "sure_to_delete": "sure to delete",
    "area": "area",
    "Number_of_subarea": "Number of subarea",
    "Edit_subarea": "Edit subarea",
    "Old_shipment_number": "Old shipment number",
    "scanning_time": "scanning time",
    "shelf": "shelf",
    "Add_shelf": "Add shelf",
    "Export_the_required_location_code_size": "Export the required location code size",
    "Export": "Export",
    "Number_of_layers": "Number of layers",
    "Add_layers": "Add layers",
    "location": "location",
    "Add_location": "Add location",
    "Failed_to_get_the_warehouse_shelf_list": "Failed to get the warehouse shelf list",
    "Failed_to_get_the_layer_number_of_warehouse_list": "Failed to get the layer number of warehouse list",
    "Failed_to_get_the_warehouse_location_list": "Failed to get the warehouse location list",
    "print": "print",
    "Select_All": "Select All",
    "All_complete": "All complete",
    "Please_select_the_print_item_first": "Please select the print item first",

  },
  deliveryReservation: {
    "Daily_Reservation": "Daily Reservation",
    "Last_month": "Last month",
    "today": "today",
    "Next_month": "Next month",
    "Reservation_status": "Reservation status",
    "piece": "piece",
    "Daily_delivery": "Daily delivery",
    "Delivery_instructions": "Delivery instructions",
    "Reservation_time": "Reservation time",
    "Delivery_time": "Delivery time",
    "Shipment_number/shipping_mark": "Shipment number/shipping mark",
    "pieces": "pieces",
    "Cancel_delivery": "Cancel delivery",
    "Confirm_whether_to_cancel_delivery": "Confirm whether to cancel delivery",
    "Successfully_cancel_delivery": "Successfully cancel delivery",
    "Failed_to_cancel_delivery": "Failed to cancel delivery",
    "Failed_to_get_data": "Failed to get data",
    "export": "export",
    "exporting": "exporting",
    "Export_successfully": "Export successfully",
    "Export_unsuccessfully": "Export unsuccessfully",
    "delivery_number": "delivery number",
    "shipping/outer_box_label": "shipping/outer box label",
    "load_output_number": "load output number",
    "filter": "filter",
    "reset": "reset",
    "shipping": "shipping",
    "driving": "driving",
    "confirm": "confirm",
    "cancel": "cancel",
  },
  workorder: {
    "Work_order_number": "Work order number",
    "Associated_order_number": "Associated order number",
    "Operating_state": "Operating state",
    "work_order_node": "work order node",
    "Work_order_type": "Work order type",
    "Problem_type": "Problem type",
    "Creation_time": "Creation time",
    "creator": "creator",
    "Processing_department": "Processing department",
    "filter": "filter",
    "reset": "reset",
    "customer_code": "customer code",
    "Current_processing_department": "Current processing department",
    "Current_processor": "Current processor",
    "Associated_work_order": "Associated work order",
    "Current_node_time": "Current node time",
    "Total_time_spent": "Total time spent",
    "Claim_time": "Claim time",
    "Prescription_of_claim": "Prescription of claim",
    "Operating_time": "Operating time",
    "Description": "Description",
    "remark": "remark",
    "Latest_communication_content": "Latest communication content",
    "view": "view",
    "All": "All",
    "Wait_to_be_done": "Wait to be done",
    "Have_done": "Have done",
    "My_creation": "My creation",
    "Copy_me": "Copy me",
    "Withdrawn": "Withdrawn",
    "rejected": "rejected",
    "submitted": "submitted",
    "finished": "finished",
    "Obsolete": "Obsolete",
    "Have_already_been_created": "Have already been created",
    "In_process": "In process",
    "Failed_to_get_a_CC": "Failed to get a CC",
    "Failed_to_get_the_work_order_type_list": "Failed to get the work order type list",
    "Failed_to_get_the_problem_type_list": "Failed to get the problem type list",
    "Failed_to_get_the_department_list": "Failed to get the department list",
    "Failed_to_get_the_employee_list": "Failed to get the employee list",
    "Failed_to_get_the_work_order_list": "Failed to get the work order list",
    "Failed_to_change_cc_status": "Failed to change  cc status",
    "Basic_information": "Basic information",
    "View_the_change_mark_requirements": "View the change mark requirements",
    "Attached_file": "Attached file",
    "Attached_picture": "Attached picture",
    "Processing_information": "Processing information",
    "Whether_to_view": "Whether to view",
    "Owning_warehouse": "Owning warehouse",
    "Communication_information": "Communication information",
    "Communication_reply": "Communication reply",
    "issuer": "issuer",
    "Carbon_copy_recipient": "Carbon copy recipient",
    "Communication_content": "Communication content",
    "Claim_the_work_order": "Claim the work order",
    "Processing_work_order": "Processing work order",
    "Work_order_processing_log": "Work order processing log",
    "unfold": "unfold",
    "handle": "handle",
    "Pack_up": "Pack up",
    "processor": "processor",
    "Check_the_time": "Check the time",
    "Node_time_consuming": "Node time consuming",
    "Processing_remarks": "Processing remarks",
    "Are_you_sure_you_want_to_claim_a_work_order?": "Are you sure you want to claim a work order?",
    "confirm": "confirm",
    "Cancel": "Cancel",
    "Are_you_sure_you_want_to_complete_a_work_order?": "Are you sure you want to complete a work order?",
    "source": "source",
    "Whether_internal_work_order": "Whether internal work order",
    "initiator": "initiator",
    "Commit_processing": "Commit processing",
    "Finished_work_order": "Finished work order",
    "Withdrawal_processing": "Withdrawal processing",
    "Scrap_ticket": "Scrap ticket",
    "Failed_to_get_domain_name": "Failed to get domain name",
    "Failed_to_get_details": "Failed to get details",
    "Failed_to_get_chat_list": "Failed to get chat list",
    "Failed_to_get_the_log_list": "Failed to get the log list",
    "Reason_for_withdrawal": "Reason for withdrawal",
    "Cause_of_rejection": "Cause of rejection",
    "Reason_for_abandonment": "Reason for abandonment",
    "Claiming": "Claiming",
    "Claim_successfully": "Claim successfully",
    "Failed_to_claim_the_work_order": "Failed to claim the work order",
    "In_completion": "In completion",
    "Complete_success": "Complete success",
    "Failed_to_complete_the_work_order": "Failed to complete the work order",
    "Number_of_packages": "Number of packages",
    "Shipping_slip_number": "Shipping slip number",
    "Old_shipment_number": "Old shipment number",
    "Shipping_mark": "Shipping mark",
    "New_shipment_number": "New shipment number",
    "Old_SKU_logo": "Old SKU logo",
    "New_SKU_logo": "New SKU logo",
    "Please_fill_in": "Please fill in",
    "required": "required",
    "Picture_upload": "Picture upload",
    "Only_support": "Only support",
    "isoformat": "isoformat",
    "Being_submitted": "Being submitted",
    "Submit_successfully": "Submit successfully",
    "Commit_failure": "Commit failure",
    "Processing_result": "Processing result",
    "Failed_to_obtain_the_service_provider_list": "Failed to obtain the service provider list",
    "task_code": "task code"

  },
  error: {
    "Sorry_the_page_you_are_looking_for_is_missing": "Sorry, the page you are looking for is missing",
    "To_check_whether_the_URL_you_entered_is_correct_please_click_the_button_below_to_return_to_the_home_page": "To check whether the URL you entered is correct, please click the button below to return to the home page",
    "Back": "Back"
  },
  other: {
    "batch_intercept": "batch intercept",
    "view_excel_content": "view excel content",
    "tc_order_number": "tc order number",
    "tc_order_id": "tc order id",
    "Shipments_sent_to_Full_or_FBA": "Shipments sent to Full or FBA",
    "change_mark": "change mark",
    "hour": "hour",
    "Export_location_information_(trial)": "Export location information (trial)",
    "have_joined_downlod_queue": "have joined downlod queue",
    "Edit_information": "Edit information",
    "Confirm_the_changes?": "Confirm the changes?",
    "Please_enter_search_criteria": "Please enter search criteria",
    "search": "search",
    "return_claim": "return claim",
    "return_code": "return code",
    "Shipment_number": "Shipment number",
    "export_multi_subarea_outwarehouse_order_list": "export multi subarea outwarehouse order list",
    "secondary_shipment/outer_box_label": "secondary shipment/outer box label",
    "pcs": "pcs",
    "Failed_to_get": "Failed to get",
    "Please_enter_the_license_plate_number": "Please enter the license plate number",
    "Please_select_the_purchase_time": "Please select the purchase time",
    "Are_you_sure_to_add_another_layer_to_this_shelf?": "Are you sure to add another layer to this shelf?",
    "Are_you_sure_to_add_a_storage_location_on_this_shelf?": "Are you sure to add a storage location on this shelf?",
    "Are_you_sure_to_delete_this_shelf?": "Are you sure to delete this shelf?",
    "Are_you_sure_to_delete_the_maximum_number_of_shelves?": "Are you sure to delete the maximum number of shelves?",
    "Are_you_sure_to_delete_this_storage_location?": "Are you sure to delete this storage location?",
    "Shelf_name": "Shelf name",
    "the_number_of_columns_of_location": "the number of columns of location",
    "single": "single",
    "batch": "batch",
    "Add_a_new_container": "Add a new container",
    "Please_enter_the_container_code": "Please enter the container code",
    "The_container_code_is_too_long_Please_re_enter_it": "The container code is too long. Please re-enter it",
    "Add_new_containers_in_batches": "Add new containers in batches",
    "quantity": "quantity",
    "Please_enter_quantity": "Please enter quantity",
    "warehouse": "warehouse",
    "uploading": "uploading",
    "upload_successfully": "upload successfully",
    "out_warehouse": "out warehouse",
    "Sure_out_warehouse?": "Sure out warehouse?",
    "Dispatching": "Dispatching",
    "Successfully_dispatched_from_storage": "Successfully dispatched from storage",
    "Dispatched_from_the_warehouse_unsuccessfully": "Dispatched from the warehouse unsuccessfully",
    "Reject_the_ticket": "Reject the ticket",
    "If_final_processing_is_complete,_select_the_processor_to_make_the_response_(required)": "If final processing is complete, select the processor to make the response (required)",
    "Interior": "Interior",
    "Outside": "Outside",
    "Cargo_information": "Cargo information",
    "Export_Cargo_information": "Export cargo information",
    "inventory_anomaly": "Inventory anomaly",
    "reprint": "reprint",
    "twice": "twice",
    "Batch_update_title": "Batch update title",
    "No_file": "No file yet",
    "batch_delete": "Batch Delete",
    "You_have_selected": "You have selected",
    "pieces_of_document_information_Are_you_sure_you_want_to_delete_them": "pieces of document information. Are you sure you want to delete them",
    "In_operation": "In Operation",
    "Batch_deletion_succeeded": "Batch deletion succeeded",
    "Batch_deletion_failed": "Batch deletion failed",
    "add": "Add",
    "sku_code": "SKU Code",
    "customer_name": "Customer Name",
    "time": "Time",
    "driving": "Action",
    "view": "View",
    "SKU_quantity_*_Unit_price": "SKU Quantity * Unit Price",
    "fail_to_get": "Failed to retrieve",
    "Picking_situation": "Picking Status",
    "date": "Date",
    "abnormal_condition": "Abnormal Condition",
    "total_number_of_packages": "Total Number of Packages",
    "Pick_volume_in_the_last_seven_days": "Picking Volume in the Last Seven Days",
    "line_chart": "Line Chart",
    "histogram": "Histogram",
    "Number_of_pending_orders": "Number of Pending Orders",
    "Order_quantity_in_picking": "Order Quantity in Picking",
    "Quantity_shipped_out_today": "Quantity Shipped Out Today",
    "Refuse_to_return_to_warehouse": "Refuse to Return to Warehouse",
    "Number_of_unpacked_shelves": "Number of Unpacked Shelves",
    "Quantity_of_original_package_transfer": "Quantity of Original Package Transfer",
    "Pick_list_number": "Picking List Number",
    "type": "Type",
    "generated_time": "Generated Time",
    "creation_time": "Creation Time",
    "order_quantity": "Order Quantity",
    "Total_number_of_SKUs": "Total Number of SKUs",
    "filter": "Filter",
    "reset": "Reset",
    "Print_the_pick_list": "Print Picking List",
    "Are_you_sure_you_want_to_generate_a_pick_list": "Are you sure you want to generate a picking list"
  },
  loadingLot: {
    "delivery_number": "delivery number",
    "shipping": "shipping",
    "creator": "creator",
    "End_time_of_scanning_code": "End time of scanning code",
    "car_number": "car number",
    "filter": "filter",
    "reset": "reset",
    "driving": "driving",
    "view": "view",
    "batch_export": "batch export",
    "lot_number": "lot number",
    "Number_of_packages": "Number of packages",
    "Create_time": "create time",
    "export_data": "export data",
    "lot_information": "lot information",
    "total_pieces": "total pieces",
    "Code_scanner": "Code scanner",
    "Shipment_information": "Shipment information",
    "Code_scanning_information": "Code scanning information",
    "Code_scanning_failure_information": "Code scanning failure information",
    "tc_order_number": "tc order number",
    "Shipment_number": "Shipment number",
    "Number_of_items_to_be_shipped_out": "Number of items to be shipped out",
    "Number_of_scanned_codes": "Number of scanned codes",
    "Missing_pieces": "Missing pieces",
    "Missing_number": "Missing number",
    "TC_sub-order_number": "TC sub-order number",
    "secondary_shipping": "secondary shipping",
    "scanning_time": "scanning time",
    "Cause_of_failure": "Cause of failure",
    "transport_car_no": "license plate number",
    "create_user_realname": "creator",
    "create_time": "creation time",
    "number": "batch number",
    "scan_count": "number of scanned documents"
  },
  returnGoods: {
    "Generate_a_single_barcode": "Generate a single barcode",
    "Batch_barcode_generation": "Batch barcode generation",
    "Shipment_number/shipping_mark": "Shipment number/shipping mark",
    "Input_example": "Input example",
    "Please_enter": "Please enter",
    "Start_number": "Start number",
    "End_number": "End number",
    "Shipping_mark_delimiter": "Shipping mark delimiter",
    "If_the_number_is_set_to_1-10,_it_will_be_generated_in_the_form_of_12345-1_to_12345-10": "If the number is set to 1-10, it will be generated in the form of 12345-1 to 12345-10",
    "Cancel": "Cancel",
    "print": "print",
    "Export": "Export",
    "None": "None",
    "saving": "saving",
    "printing": "printing",
    "Print_successfully": "Print successfully",
    "Failed_to_print": "Failed to print",
    "exporting": "exporting",
    "Export_successfully": "Export successfully",
    "Failed_to_export_the_sheet": "Failed to export the sheet",
    "Platform_type": "Platform type",
    "Return_code": "Return code",
    "Number_of_packages": "Number of packages",
    "Add": "Add",
    "Fill_in_optional": "Fill in optional",
    "Shipment_number": "Shipment number",
    "Delete": "Delete",
    "Return_goods": "Return goods",
    "United_States_and_Mexico": "United States and Mexico",
    "Saving": "Saving",
    "Save_successfully": "Save successfully",
    "Failed_to_save": "Failed to save",
    "Failed_to_obtain_the_platform_type_list": "Failed to obtain the platform type list",
    "Please_select_the_platform_type!": "Please select the platform type!",
    "Failed_to_add_the_shipment_number": "Failed to add the shipment number",
    "The_return_code_can_only_be_added_once": "The return code can only be added once",
    "Please_enter_the_correct_information!": "Please enter the correct information!",
    "adding": "adding",
    "Add_successfully": "Add successfully",
    "Add_failure": "Add failure",
    "Return/US-Mexico_details": "Return/US-Mexico details",
    "TC_order_number": "TC order number",
    "Founder": "Founder",
    "Warehousing_time": "Warehousing time",
    "Total_number_of_packages": "Total number of packages",
    "Order_type": "Order type",
    "Time_of_creation": "Time of creation",
    "Order_information": "Order information",
    "TC_sub-order_number": "TC sub-order number",
    "Sub-shipment_number": "Sub-shipment number",
    "Express_tracking_number": "Express tracking number",
    "Storage_location_information": "Storage location information",
    "The_latest_update_time": "The latest update time",
    "Updater": "Updater",
    "Cargo_picture": "Cargo picture",
    "Print_sheet": "Print sheet",
    "Export_sheet": "Export sheet",
    "Download": "Download",
    "Close": "Close",
    "Failed_to_get_details": "Failed to get details",
    "Failed_to_obtain_form": "Failed to obtain form",
    "generate": "generate",
    "Express_Ticket_number": "Express Ticket number",
    "New_order": "New order",
    "Type_of_order": "Type of order",
    "Type_of_shipment": "Type of shipment",
    "Generate_barcode": "Generate barcode",
    "View_size": "View size",
    "resize": "resize",
    "Private_person": "Private person",
    "Address_template": "Address template",
    "Upload_packing_list": "Upload packing list",
    "Only_files_smaller_than_15_MB_are_supported": "Only files smaller than 15 MB are supported",
    "Modify_size_information_in_batches": "Modify size information in batches",
    "Gross_weight": "Gross weight",

  },
  inventoryCenter: {
    "Edit_operation_time": "Edit operation time",
    "Updated_in_Mexico_local_time": "Updated in Mexico local time",
    "First_shelf_time_(manual)": "First shelf time (manual)",
    "Takedown_time_(manual)": "Takedown time (manual)",
    "Exit_time_(manual)": "Exit time (manual)",
    "Warehousing_time_(manual)": "Warehousing time (manual)",
    "Shelf_time": "Shelf time",
    "Update_the_outbound_time": "Update the outbound time",
    "Outbound_time_(Mexico_time)": "Outbound time (Mexico time)",
  },
  transferWarehouse: {
    "order_number": "order number",
    "shipping_mark": "shipping mark",
    "account_manager": "account manager",
    "reception_time": "reception time",
    "estimated_time_of_delivery": "estimated time of delivery",
    "order_status": "order status",
    "audit_status": "audit status",
    "filter": "filter",
    "reset": "reset",
    "operate": "operate",
    "view": "view",
    "pending": "pending",
    "audited": "audited",
    "failed_to_get": "failed to get",
    "saving": "saving",
    "saved_successfully": "saved successfully",
    "failed_to_save": "failed to save",
    "order_information": "order information",
    "number_of_pieces": "number of pieces",
    "creation_time": "creation time",
    "shelf_start_time": "shelf start time",
    "storage_age": "storage age",
    "finish_shelf_time": "finish shelf time",
    "place_of_dispatch": "place of dispatch",
    "total_weight": "total weight",
    "receiver": "receiver",
    "destination_warehouse": "destination warehouse",
    "total_volume": "total volume",
    "customer_information": "customer information",
    "customer_name": "customer name",
    "contact_person": "contact person",
    "contact_number": "contact number",
    "shipment_information": "shipment information",
    "total_actual_weight": "total actual weight",
    "total_volume(volume_weight)": "total volume(volume weight)",
    "total_square(volume)": "total square (volume)",
    "total_square(actual_weight_divided_by_300)": "total square (actual weight divided by 300)",
    "total_quantity": "total quantity",
    "shipment_status": "shipment status",
    "days_of_storage": "days of storage",
    "hide": "hide",
    "expand": "expand",
    "tc_subtracking_number": "tc subtracking number",
    "sub_shipment_number/shipping_mark": "sub shipment number/shipping mark",
    "length_*_width_*_height_/cm": "length * width * height /cm",
    "gross_weight_/kg": "gross weight /kg",
    "scanned_picture": "scanned picture",
    "scene_picture": "scene picture",
    "sku_name": "sku name",
    "number_of_single_box": "number of single box",
    "view_picture": "view picture",
    "receive_information": "receive information",
    "shipping_contact": "shipping contact",
    "shipping_contact_number": "shipping contact number",
    "receive_signature_picture": "receive signature picture",
    "receive_picture": "receive picture",
    "repudiator": "repudiator",
    "rejection_time": "rejection time",
    "rejection_instructions": "rejection instructions",
    "explanatory_picture": "explanatory picture",
    "remark_information": "remark information",
    "customer_remarks": "customer remarks",
    "internal_remarks": "internal remarks",
    "print_sheet": "print sheet",
    "export_sheet": "export sheet",
    "export_warehouse_list": "export warehouse list",
    "export_customer_box_list": "export customer box list",
    "export_customer_service_box_list": "export customer service box list",
    "export_attachment": "export attachment",
    "off": "off",
    "download": "download",
    "new_sub_shipment_number/shipping_mark": "new sub shipment number/shipping mark",
    "change_type": "change type",
    "old_sub_shipment_number/shipping_mark": "old sub shipment number/shipping mark",
    "original_product_label": "original product label",
    "new_product_label": "new product label",
    "quantity": "quantity",
    "change_area": "change area",
    "uploading": "uploading",
    "upload_successfully": "upload successfully",
    "failed_to_upload": "failed to upload",
    "removing": "removing",
    "removed_successfully": "removed successfully",
    "failed_to_remove": "failed to remove",
    "syncing": "syncing",
    "sync_successfully": "sync successfully",
    "failed_to_sync": "failed to sync",
    "no_data_available": "no data available",
    "exporting": "exporting",
    "warehouse_list": "warehouse list",
    "added_to_the_download_queue": "added to the download queue",
    "failed_to_export": "failed to export",
    "printing": "printing",
    "print_successfully": "print successfully",
    "failed_to_print": "failed to print",
    "piece": "piece",
    "number_of_packages": "number of packages",
    "express_sheet": "express sheet",
    "reject_information": "reject information",
    "delivery_information": "delivery information",
    "operator": "operator",
    "operate_status": "operate status",
    "process_time": "process time",
    "picture": "picture",
    "remark": "remark",
    "accept": "accept",
    "reject": "reject",
    "picture_error,_please_contact_staff!": "Picture error, please contact staff!"
  }
}