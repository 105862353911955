import { watch } from 'vue'
import { computed } from "vue";
import { asyncRoutes } from './index'
import sideMenuChange from '../utils/common/side-menu-change'
import router from './index'
import store from '@/store'
import NProgress from "nprogress";
import 'nprogress/nprogress.css'
import TcMessage from '@/utils/common/tc-message'
import { getUserAllRuleTreeListAPI, getUserAllButtonRuleKeyListAPI } from "@/api/user/user";
let registerRouteFresh = true //防止刷新丢失
let routerTreeData = null
const userRouters = computed(() => store.state.user.userRouters) //用户路由

const selectPart = computed(() => store.state.user.selectPart)

// 获取用户权限树列表
const getUserAllRuleTreeList = async () => {
    try {
        routerTreeData = await getUserAllRuleTreeListAPI({});
        store.commit('user/addAuthCenters', routerTreeData)
        if (routerTreeData.length == 0) {
            TcMessage({ type: 'error', text: '该账号无权限' });
            localStorage.clear()
            return
        }
    } catch (error) {
        error.error ? TcMessage({ type: 'error', text: error.error }) : console.log(error);
    }
};

// 获取按钮权限
const getUserBtnsAuth = async () => {
    try {
        let res = await getUserAllButtonRuleKeyListAPI({});
        store.commit('user/addUserBtns', res)
        // console.log('按钮',res);
    } catch (error) {
        error.error ? TcMessage({ type: 'error', text: error.error }) : console.log(error);
    }
};

// 动态添加路由
const compareChildren = (allData, authData) => {
    allData.forEach(allItem => {
        authData.forEach(authItem => {
            if (allItem.name === authItem.key) {
                allItem.meta["auth"] = true
                if (allItem.children && authItem.children) {
                    return compareChildren(allItem.children, authItem.children)
                }
            }
            // // 未添加权限时使用
            // if (allItem.name !== authItem.key) {
            //     allItem.meta["auth"] = true
            //     if (allItem.children && authItem.children) {
            //         return compareChildren(allItem.children, authItem.children)
            //     }
            // }
        })
    })
}

const addRouters = async () => {
    await getUserAllRuleTreeList()
    asyncRoutes.forEach(centerItem => {
        routerTreeData.forEach(treeCenter => {
            // console.log('有权限的路由treeCenter.children',treeCenter.children);

            if (centerItem.name === treeCenter.key) {
                // 进入child比较
                centerItem['auth'] = true
                compareChildren(centerItem.children, treeCenter.children)
            }
        })
    })
}

// 监听selectPart的变化，添加对应模块路由
watch(() => store.state.user.selectPart, () => {
    let nowPath = localStorage.getItem("nowPath")
    selectPart.value && router.replace(nowPath)
})
 
// 路由守卫
router.beforeEach(async (to, from, next) => {
    const authKey = localStorage.getItem("authKey")
    NProgress.start()
    if (authKey) {
        if (to.path === '/login') {
            next("/")
        } else {
            // 1.浏览器直接输入/访问 2.通过router.push('/')进行跳转
            if (to.path === '/') {
                await addRouters()
                sideMenuChange(store.state.user.authCenters[0]?.key)
                localStorage.removeItem("selectPart")
                // 通过router.push('/')进行跳转
                if (userRouters.value.length) {
                    next({ path: store.state.user.userRouters[0]?.children[0]?.path })
                    return
                }
            }

            // 浏览器刷新
            if (registerRouteFresh) {
                await addRouters()
                await getUserBtnsAuth()
                store.commit("user/clearUserRouters");
                // TODO : 路由动态添加
                let userAllRoutes = []
                // 获取一级
                asyncRoutes.forEach(item => {
                    item.auth ? userAllRoutes.push(item) : ""
                })
                userAllRoutes.forEach(item => {
                    let userSecondRoutes = []
                    item.children.forEach(childItem => {
                        let userThirdRoutes = []
                        if (childItem.meta.auth) {
                            userSecondRoutes.push(childItem)
                            childItem.children.forEach(thirdItem => {
                                if (thirdItem.meta.auth) {
                                    userThirdRoutes.push(thirdItem)
                                }
                            })
                        }
                        // 获取三级
                        childItem.children = userThirdRoutes
                    })
                    // 获取二级
                    item.children = userSecondRoutes
                })
                userAllRoutes.forEach(item => {
                    item.children.forEach(childItem => {
                        // 刷新时，路由导航添加固定选择
                        item.name == selectPart.value ? store.commit('user/addUserRouters', childItem) : ""
                        router.addRoute('index', childItem)
                    })
                })
                registerRouteFresh = false;
                localStorage.setItem('index', store.state.user.userRouters[0]?.children[0]?.path)
                next({
                    path: localStorage.getItem("nowPath") || store.state.user.userRouters[0]?.children[0]?.path,
                    query: to.query
                })
            } else {
                next()
            }
        }
    } else {
        if (to.path === '/login') {
            registerRouteFresh = true;
            next()
            return
        }
        next('/login')

    }
})

router.afterEach(() => {
    NProgress.done()
})