import { asyncRoutes } from "@/router"
import router from "@/router";
import store from "@/store";
/**
 * @param centerName String 改变路由的中心名称
 * 最终跳转执行permission.js里面  （监听selectPart的变化，跳转对应路由）
 */
export default (centerName) => {
    // 跳转的中心没有在权限内，不执行以下
    const tempIndex = store.state.user.authCenters.findIndex(item => item.key === centerName)
    if (tempIndex === -1) return
    // 当前中心与目的中心相同，不执行
    if (centerName === localStorage.getItem("selectPart")) return

    store.commit("user/clearUserRouters");
    asyncRoutes.forEach(item => {
        if (item.name == centerName) {
            item.children.forEach(childItem => {
                store.commit('user/addUserRouters', childItem)
                router.addRoute('index', childItem)
            })
        }
    })
    localStorage.setItem("nowPath", store.state.user.userRouters[0]?.children[0]?.path)
    localStorage.setItem("selectPart", centerName)
}   