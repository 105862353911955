import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-01d996de"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "date-picker"
};
const _hoisted_2 = {
  key: 0,
  class: "daterange"
};
const _hoisted_3 = {
  key: 1,
  class: "datetime"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_date_picker = _resolveComponent("el-date-picker");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.$attrs.type == 'daterange' ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_el_date_picker, {
    modelValue: $setup.keyWord,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.keyWord = $event),
    "value-format": "YYYY-MM-DD HH:mm:ss",
    type: "daterange",
    "range-separator": "-",
    "start-placeholder": "0000-00-00",
    "end-placeholder": "0000-00-00",
    clearable: true,
    teleported: false,
    "prefix-icon": "none"
  }, null, 8, ["modelValue"])])) : (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_el_date_picker, {
    clearable: true,
    "value-format": _ctx.$attrs.type == 'date' ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm:ss',
    placeholder: "0000-00-00",
    modelValue: $setup.keyWord,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $setup.keyWord = $event),
    type: _ctx.$attrs.type,
    teleported: false
  }, null, 8, ["value-format", "modelValue", "type"])]))]);
}