import { createI18n } from 'vue-i18n'
// element-plus 中的语言配置
// import elementEnLocale from 'element-plus/lib/locale/lang/en'
// import elementZhLocale from 'element-plus/lib/locale/lang/zh-cn'
// import elementEsLocale from 'element-plus/lib/locale/lang/es'

import enLocale from './en'
import zhLocale from './zh'
import esLocale from './es'

const messages = {
    en: {
        ...enLocale,
        // ...elementEnLocale
    },
    zh: {
        ...zhLocale,
        // ...elementZhLocale
    },
    es: {
        ...esLocale,
        // ...elementEsLocale
    }
}
const i18n = createI18n({
    legacy: false,
    globalInjection: true,  // 全局模式，可以直接使用 $t
    locale: localStorage.getItem("language") || 'zh',
    messages: messages
})

export default i18n