export default {
  login: {
    'Welcome_to_TC_system': "Bienvenido al sistema TC",
    "Login_account": "Entrar a cuenta",
    "Please_enter_your_login_account": "Por favor ingrese una cuenta de login",
    "Login_password": "Login contraseña",
    "Please_enter_your_login_password": "Por favor ingrese su contraseña de acceso",
    "Remember_the_account_password": "Recuerda la contraseña por favor",
    "Enterprise_wechat_login": "Empresa wechat iniciar sesión",
    "logining": "Iniciar sesión en",
    "Login_success": "Inicio de sesión exitoso",
    "Login_failure": "Inicio de sesión fallado",
    "exit": "salida",
    "exiting": "Salida en curso",
    "Exit_successfully": "Éxito de salida",
    "Exit_failure": "Fracaso de salida",
    "Welcome_to_login_TC_system": "Bienvenido a iniciar sesión en el sistema TC",
    "account": "acceso de cuenta",
    "Password": "contraseña",
    "Please_enter_login_account": "Por favor ingrese la cuenta de acceso",
    "Please_enter_login_password": "Por favor ingrese la contraseña",
    "Log_in_Wecom": "Acceso de Wecom",
    "Log_in": "Acceso",
    "It_is_on_the_access_road": "Está en camino de acceso",
    "Access_error": "error de acceso",

  },
  menu: {
    "WarehouseData": "Datos de almacén",
    "operate_time": "Tiempo de operación",
    "View_details": "Ver los detalles",
    "Warehousing_management": "Gestion de almacenes",
    "Out_warehouse_management": "Gestión de salida",
    "Order_management": "Gestión de pedidos",
    "Pallet_management": "Gestión de palets",
    "Warehousing_anomaly": "Excepción de entrada",
    "Outgoing_exception": "Excepción de salida",
    "Operation_exception": "Anomalía operativa",
    "Vehicle_management": "Gestión de vehículos",
    "Order_list": "Catálogo de pedidos",
    "warehouse_data": "dato de bodega",
    "classification_data": "dato de clasificación",
    "classification_details": "detalles de clasificación",
    "pela_details": "detalles de pelá",
    "cargo_entry_management": "administración de ingreso de carga",
    "cargo_entry_details": "detalles de ingreso de carga",
    "Shipment_details": "detalles de envío",
    "load_output_administration": "administración de salida de carga",
    "load_output_details": "detalles de salida de carga",
    "order_administration": "administración de pedidos",
    "order_details": "detalles de pedidos",
    "abnormal_load_input_situation": "situación anormal de ingreso de carga",
    "abnormal_situation_details": "detalles de situación anormal",
    "load_output_abnormal_situation": "situación anormal de salida de carga",
    "abnormal_driving_situation": "situación anormal de manejo",
    "container_administration": "administración de recipiente",
    "container_details": "detalles de recipiente",
    "location_management": "gestión de ubicación",
    "classification_management": "gestión de clasificación",
    "platform_management": "gestión de tarima",
    "car_management": "gestión de coches",
    "Enterprise_architecture_management": "Gestión de la arquitectura empresarial",
    "System_setting": "Configuración del sistema",
    "Department_setting": "Configuración del departamento",
    "Employee_account_management": "Gestión de cuentas de empleados",
    "Role_rights_management": "Administración de permisos de rol",
    "Role_setting": "Configuración de roles",
    "Authority_management": "Gestión de derechos",
    "Work_order_management": "Gestión de órdenes de trabajo",
    "Add_new_work_order": "Crear un nuevo ticket",
    "Work_order_details": "Detalles del boleto",
    "Customer_service_Settings": "Configuración del servicio de atención al cliente",
    "Work_order_type": "Tipo de billete",
    "Problem_type": "Tipo de problema",
    "Drafts": "Cubo de borrador",
    "check": "Ver",
    "add": "De nueva construcción",
    "intercept": "Intercepción de salida",
    "updateFiles": "Actualizar archivos adjuntos",
    "delete": "Eliminar",
    "cancel": "Cancelar la entrega",
    "edit": "Editar",
    "download": "Descargar",
    "No_owner_claimed_the_goods": "ningún propietario reclama la mercancía",
    "Mercado_Libre_Reservation": "Reserva de Mercado Libre",
    "Reservation_status": "Proceso de reserva",
    "Check_the_shipment_number": "Consulta de envío",
    "Abnormal_data": "Datos anormales",
    "Basic_setup": "Ajuste básico",
    "Warehouse_details": "Detalles de bodega",
    "return_claim": "Devolución y reclamación",
    "loadingLot": "Lote de carga",
    "lotDetail": "Lote quienes",
    "return_goods": "Retorno/Estados Unidos México",
    "returnGoods": "Retorno/Estados Unidos México",
    "warehouse": "Ingreso de carga",
    "outwarehouse": "Salida de carga",
    "refuseOutwarehouse": "Rechazo de devolución",
    "transfer": "Tranferir",
    "inventory": "Inventario",
    "pickGoods": "Seleción y Clasificación",
    "listGoods": 'Manipulación de carga',
    "outerSheet": "Etiqueta de envío",
    "productSheet": "Etiqueta de productos",
    "findGoods": "Comprobar la mercancía",
    "claimGoods": "Reclamación de devolución",
    "truckLoading": "Carga y embarque",
    "picsUpload": "Carga de imágenes",
    "changeLabel": "Cambio de etiqueta",
    "checkZone": "Comprobar la ubicación",
    "Update": "Actualizar",
    "pda": "pda",
    "areaScan": "Área de escaneo",
    "goods_ageing": "Edad Las mercancías",
    "zoneInventory": "Ubicación del inventario",
    "workSheet": "Procesamiento de órdenes de trabajo",
    "storage_list": "Lista de almacenamiento",
    "first_route_order": "Primer orden de ruta",
    "transfer_warehouse_order": "Transferencia orden de depósito",
    "order_detail": "Detalle del pedido",
    "transferWarehouseProcess": "Proceso de transferencia de almacén",
    "transferToWarehouse": "Transferencia a almacén",
    "drop_shipping_outwarehouse": "Abandono de envío de almacén",
    "drop_shipping_outwarehouse_detail": "Abandono de envío de almacén detalle",
    "picking_task": "Tarea de recogida",
    "picking_list": "Lista de selección",
    "picking_outwarehouse": "Recogida de residuos",
    "task_detail": "Detalle de tareas",
    "warehousing_manage": "Gestión del almacenamiento",
    "container_manage": "Gestión de contenedores",
    "location_manage": "Gestión de ubicaciones",
    "distribution_manage": "Gestión de la distribución",
    "shelves_manage": "La gestión de las plataformas",
    "pull_on_shelves_list": "Tirar de la lista de los est",
    "pull_off_shelves_list": "Lista para sacar de los estantes",
    "warehouse_manage": "Gestión del almacén",
    "goods_stock": "Existencias de bienes",
    "sku_stock": "Stock de sku",

    "inventory_adjustment": "Ajuste de inventario",
    "container_detail": "Detalle del contenedor",
    "product_information": "Información del producto",
    "order_manage": "Gestión de pedidos",
    "all_order": "Todos orden",
    "shein_order": "SHEIN orden",
    "warehouse_detail": "Detalle del almacén",
    "subarea_detail": "Detalle de los subsectores",
    "location_detail": "Detalle de la ubicación",
    "goods_detail": "Detalle de la mercancía",
    "general_situation": "Resumen",
    "batchOutWarehouse": "Lote fuera del almacén",
    "UpdateTheOutboundTime": "Actualizar la hora de salida",
    "DestroyGoods": "Solicitud de destrucción de bienes",
    "system_setting": "Configuración del sistema",
    "courier_freight_manage": "Gestión de gastos de envío de empresas de entrega rápida",

    "setupOutaccount": "Configuración de la cuenta de salida",
    "shipment": "Información del número de envío",
    "changeShipment": "Cambiar el número de envío para cambiar etiquetas",
    "batchDistributionWorkSheet": "Hoja de trabajo de distribución por lotes"
  },
  selectPart: {
    "Operation_center": "Operación",
    "order_center": "Pedidos",
    "Enterprise_center": '',
    "Service_center": 'Gestión de órdenes de trabajo',
    "pda": "pda",
    "Inventory_center": "inventario",
    "distribution_center": "Centro de distribución"
  },
  warehouseData: {

    "Goods_to_be_listed": "Productos para colocar en el estante",
    "Container_code_to_be_listed": "Código del contenedor a ser listado",
    "Current_pieces": "piezas ahora",
    "Current_pieces_of_order/ticket": "cantidad de pedidos actuales/boleto",
    "Total_weight": "peso total",
    "Total_weight/volume": "capacidad total/volumen",
    "Pending_arrival_orders_at_the_warehouse": "pedidos de llegada pendiente de almacén",
    "Orders_pending_handling": "pedidos pendiente de manejo",
    "abnormal_orders": "pedidos anormales",
    "orders_that_have_been_held_for_a_long_time": "pedidos que se han reservado por mucho tiempo",
    "Total_pieces": "piezas totales",
    "Total_volume": "volumen total",
    "Total_order/ticket": "pedidos totales/boleto",
    "Day": "día",
    "Month": "mes",
    "Year": "año",
    "cargo_intake_today/piece": "ingreso de carga hoy/pieza",
    "Morning": "mañana",
    "Afternoon": "tarde",
    "cargo_departure_today/piece": "salida de carga hoy/pieza",
    "Handling_today/piece": "manejo hoy/pieza",
    "Classification_of_warehouse_area": "áreas de almacén",
    "Import_data": "importar datos",
    "reservation_space_is_less_than": "espacio de reserva es menor que",
    "cargo_entry_worker": "trabajador de ingreso de carga",
    "Time_of_warehousing": "hora de ingreso de carga",
    "Warehouse": "bodega donde estás ahora ",
    "classification_area": "área de clasificación",
    "pallet_number": "número de tarima",
    "deposit_time": "tiempo de depósito",
    "secondary_shipment_number": "número de envío secundaria",
    "secondary_shipping/secondary_outer_carton_label": "envío secundaria/etiqueta de caja exterior secundaria",
    "Pieces": "piezas",
    "Weight": "peso",
    "Number_of_shipment": "número de envío",
    "Number_of_entering_warehouse": "número de ingreso de carga",
    "Number_of_products/tag": "envío/etiqueta de caja exterior de caja",
    "choose_classification_area": "elegir área de clasificación",
    "filter": "filtro",
    "Reset": "reinicio",
    "Client_name": "nombre de clientes",
    "Salesman": "vendedor",
    "deposit_time/day": "tiempo de depósito/día",
    "handling": "manejo",
    "Check": "revisar",
    "Please_enter_content": "Por favor ingrese contenido ",
    "No_region_storage_information_was_entered": "No se ha introducido información de área reservada",
    "Storage_space_less_than": "Espacio de reserva es menor que",
    "Search_results": "Resultado de busca",
    "Total": "Piezas totales",
    "Old_shipment_number": "Número de envío viejo ",
    "Old_sub-shipment_number/shipping_mark": "Antiguo número de subenvío/marca de envío",
    "change_order_number": "Corregir número de envío",
    "region_name": "Nombre de zona",
    "status": "Estado",
    "Update_time": "Tiempo actualizado",
    "volume": "Volumen",
    "Data_export": "Exportar datos",
    "Mercado_Libre_Reservation": "Reserva de Mercado Libre",
    "Reservation_status": "Proceso de reserva",
    "Check_the_shipment_number": "Consulta de envío",
    "Abnormal_data": "Datos anormales",
    "Basic_setup": "Ajuste básico",
    "Warehouse_details": "Detalles de bodega",
    "Already_listed": "Ya en estante",
    "Taken_down": "Ha sido retirado"
  },
  warehousing: {
    "delivery_number": "número de envío",
    "cargo_entry_number": "número de ingreso de carga",
    "shipping/outer_box_label": "envío/etiqueta de caja exterior",
    "state": "estado",
    "Container_pick_up_date": "fecha de retirar contenedores",
    "cargo_entry_time": "hora de ingreso de carga",
    "import_data": "importar datos",
    "export_data": "exportar datos",
    "Container_number": "Número del contenedor",
    "SO_number": "SO Número",
    "filter": "filtro",
    "reboot": "reinicio",
    "pick_up_order_number/container_number": "número de recoger pedido/número de contenedor",
    "type_of_transport": "tipo de transporte",
    "related_shipping_quantity/pcs": "cantidad de envío relacionado/piezas",
    "total_pieces/piece": "piezas totales/pieza",
    "total_volume/cubic_meters": "volumen total/metros cúbicos",
    "total_weight/kg": "peso toal/kilogramo",
    "warehouse_receipt_amount/piece": "cantidad de ingreso de bodega/pieza",
    "review": "revisar",
    "secondary_shipment_number": "número de envío secundaria",
    "secondary_shipping/secondary_outer_carton_label": "envío secundaria/etiqueta de caja exterior secundaria",
    "product_parts/parts": "piezas de productos/piezas",
    "volume/cubic_meters": "volumen/metros cúbicos",
    "weight/kg": "peso/kilogramo",
    "have_been_entered": "se han ingresado",
    "total_pieces": "piezas totales",
    "total_weight": "peso total",
    "total_volume": "volumen total",
    "cargo_entry_parts": "piezas de ingreso de carga",
    "pieces_without_charge_entry": "piezas sin ingreso de carga",
    "driving": "manejo",
    "print_income_sheet": "imprimir hoja de ingreso",
    "export_income_sheet": "exportar hoja de ingreso",
    "warehousing": "Pendiente de ingreso",
    "Failed_to_get_warehousing_list": "Han fallado obtener lista de ingreso",
    "Transport_type": "Tipo de transportante",
    "Failed_to_get_warehousing_details": "Han fallado obtener detalles de ingreso",
    "Failed_to_get_waybill_details": "Han fallado obtener detalles de pedidos",
    "shipping": "envío",
    "secondary_shipping": "envío secundaria",
    "location_code/container_code": "código de ubicación/código de envase",
    "all": "Todo",
    "warehoused": "Se han salido",
    "exporting": "Está exportando",
    "Export_successfully": "Exportación exitosa",
    "printing": "Está imprimiendo",
    "Print_successfully": "Imprimir exitosamente",
    "Print_unsuccessfully": "Imprimir falsamente",
    "Company_logo": "Empresa logo",
    "Export_unsuccessfully": "Exportación falsa"
  },
  outwarehouse: {
    "delivery_number": "número de envío",
    "load_output_number": "número de salida de carga",
    "shipping/outer_box_label": "envío/etiqueta de caja exterior",
    "state": "estado",
    "creation_date": "fecha de creación",
    "cargo_departure_time": "hora de salida de carga",
    "related_shipping_quantity/pcs": "cantidad de envío relacionado/piezas",
    "scan_quantity/pieces": "cantidad de escaneo/piezas",
    "person_responsible_for_shipping": "persona responsable de envío",
    "shipping_car_number": "número de coche de envío",
    "secondary_shipment_number": "número de envío secundaria",
    "secondary_shipping/secondary_outer_carton_label": "envío secundaria/etiqueta de caja exterior secundaria",
    "successful_scan_parts": "piezas de escaneo exitoso",
    "parts_without_scanning": "piezas sin escaneo",
    "download_authorization": "descargar autorización",
    "print_output_sheet": "imprimir hoja de salida",
    "export_output_sheet": "exportar hoja de salida",
    "Create_delivery_list": "Hoja de salida recién construida",
    "title": "Título",
    "Total": "Piezas totales",
    "related_shipping_quantity": "Cantidad coherente de envío",
    "successful_number": "Piezas escaneadas",
    "reject_number": "Piezas rechazadas",
    "intercept_number": "Piezas detenidas",
    "creator": "Fundador",
    "address": "Dirección",
    "Create_time": "Tiempo de creación",
    "Delivery_time": "Tiempo de envíar cajas a bodega ",
    "remark": "Nota",
    "driving": "Manejo",
    "view": "Chequear",
    "delete": "Eliminar",
    "Are_you_sure_to_delete": "Seguro que eliminar?",
    "confirm": "Sí",
    "cancel": "Cancelar",
    "waiting_for_out_warehouse": "Pendiente de salida ",
    "warehousing": "Están saliendo las cargas ",
    "warehoused": "Se han salido las cargas",
    "out_warehouse_abnormally": "Salida anormal de cargas",
    "deleting": "Está eliminando",
    "Successfully_delete": "Eliminar exitosamente",
    "Delete_unsuccessfully": "Eliminar falsamente",
    "Failed_to_get_out_warehouse_list": "Falsamente obtener lista de salida",
    "Attached_file": "Archivo de anexo",
    "Only_support": "Solo apoyo ",
    "format": "Formato",
    "upload": "Subir",
    "save": "Guardar",
    "saving": "Está guardando",
    "Save_successfully": "Guardar exitosamente",
    "Save_unsuccessfully": "Guardar falsamente",
    "Intercepted_number_of_out_warehouse": "Piezas detenidas de salida",
    "Scan_list": "Lista de escaneo",
    "batch_out_warehouse": "Salida por lotes",
    "destroy_goods": "Solicitud de destrucción de bienes",
    "out_warehouse_intercept": "Detener las cargas de salida",
    "Are_you_sure_to_out_warehouse?": "Seguro en salida?",
    "File_list": "Lista de archivos",
    "batch_download": "Descargar por lotes",
    "take_a_photo_and_send_back": "Tomar foto y devolverla",
    "Download": "Descargar",
    "close": "Cerrar",
    "Reject_list": "Lista de rechazo",
    "reject_reason": "Razón de rechazo",
    "filter": "Filtro",
    "reset": "Reiniciar",
    "edit": "Editar",
    "Download_attachment": "Descargar anexo",
    "Update_attachment": "Actualizar anexo",
    "Export_the_reject_list": "Exportar lista de rechazo",
    "All": "Todo",
    "Scanned": "Escaneo hecho",
    "To_be_scanned": "Pendiente de escaneo",
    "rejection": "Rechazo",
    "Failed_to_get_out_warehouse_details": "Falsamente obtener detalles de salida ",
    "Please_check_first": "Marque primero",
    "Batch_delivery_successfully": "Salida exitosa por lotes",
    "Batch_out_warehouse_unsuccessfully": "Salida falsa por lotes",
    "Failed_to_get_fileList": "Falsamente obtener lista de archivo",
    "File_is_empty": "Archivo está vacio",
    "Download_successfully": "Descargar exitosamente",
    "Download_unsuccessfully": "Descargar falsamente",
    "Failed_to_get_scan_list": "Falsamente obtener lista de escaneo",
    "The_attached_file_is_empty": "Archivo de anexo está vacio",
    "exporting": "Está exportando",
    "Export_successfully": "Exportar exitosamente",
    "Export_unsuccessfully": "Exportar falsamente",
    "Print_unsuccessfully": "Imprimir falsamente",
    "printing": "Está imprimiendo ",
    "Print_successfully": "Imprimir exitosamente",
    "intercept_reason": "Razón detenida",
    "intercepting": "Se está impidiendo la salida",
    "Intercept_successfully": "Exitosamente se impidió la salida",
    "Intercept_unsuccessfully": "Falsamente se impidió la salida",
    "Edit_reject_reason": "Editar razón de rechazo",
    "editing": "Están editando",
    "Edit_successfully": "Editar exitosamente",
    "Edit_failure": "Editar falsamente",
    "Please_upload_the_attached_file": "Suba archivo de anexo por favor",
    "updating": "Está actualizando",
    "Update_successfully": "Actualizar exitosamente",
    "Export_warehouse_orders_in_batches": "Exportación masiva de órdenes de salida",
    "Number_of_pieces_to_be_issued": "Número de piezas a emitir",
    "Platform": "plataforma",
    "Export_excel_data_in_batches": "Exportar datos Excel en lotes",
    "Export_the_pdf_of_the_swap": "Exportar PDF con cambio de marca",
    "Change_label_list": "Lista de cambio de etiqueta",
    "Change_label_information_table": "Tabla de información sobre el cambio de etiqueta",
    "Error_table_of_label_change_information": "Tabla de errores de información sobre cambios de etiquetas",
    "List_of_unchanged_labels": "Lista de etiquetas sin cambio",
    "Storage_location_area": "Zona de ubicación de almacenamiento",
    "change_label_status": "Cambio del estado de la etiqueta",
    "operator": "operador",
    "Error_cause": "Causa de Error",
    "Estimated_weight": "Peso estimado",
    "Estimated_volume": "Volumen estimado",
    "abnormal_remark": "Observación anormal",
    "Number_of_items_that_should_be_shipped_out": "Número de artículos que deben ser enviados",
    "Number_of_items_that_have_been_shipped_out": "Número de artículos que han sido enviados",
    "Number_of_items_that_have_not_been_shipped_out": "Número de artículos que no han sido enviados",
    "Number_of_intercepted_items": "Número de artículos interceptados",
    "Number_of_rejected_items": "Número de artículos rechazados",
    "Number_of_items_that_should_have_outer_box_labels_changed": "Número de artículos que deberían haber cambiado las etiquetas de la caja exterior",
    "Number_of_items_that_have_not_had_outer_box_labels_changed": "Número de artículos que no han cambiado las etiquetas de la caja exterior",
    "Batch_processing_abnormal_child_orders": "Procesamiento por lotes de pedidos secundarios anormales",
    "Appendix_to_authorization": "Apéndice de la autorización",
    "Changing_attachment": "Apéndice de cambio de etiqueta",
    "Edit_Excel_Content": "Editar contenido de Excel",
    "Multiple_separated_by_Chinese_commas": "Múltiples separados por comas chinas, por ejemplo:",
    "Old_shipment_number_information": "Información sobre el número de envío anterior",
    "Please_enter_remarks": "Por favor, ingrese comentarios",
    "Update_failure": "Error de actualización",
    "Please_wait_until_the_file_is_uploaded": "Por favor, espere hasta que el archivo se haya cargado completamente",
    "Edit_SKU": "Editar SKU",
    "replace_SKU_or_not": "¿Reemplazar SKU o no",
    "Change_total_quantity": "Cambiar cantidad total",
    "Old_SKU": "SKU antiguo",
    "New_SKU": "SKU nuevo",
    "Update_total_quantity": "Actualizar cantidad total de reemplazos",
    "Add": "Agregar",
    "Confirm": "Confirmar",
    "change": "Reemplazar",
    "Do_not_replace": "No reemplazar",
    "Please_Enter_Old_SKU": "Ingrese el SKU antiguo",
    "Please_Enter_New_SKU": "Ingrese el SKU nuevo",
    "Please_Enter_Total_Replacement_Quantity": "Ingrese la cantidad total de reemplazos",
    "Total_Replacement_Quantity_Cannot_Be_Less_Than_0": "La cantidad total de reemplazos no puede ser menor que 0",
    "The_List_Cannot_Be_Empty": "La lista no puede estar vacía",
    "Please_Enter_Quantity": "Ingrese la cantidad",
    "Quantity_Cannot_Be_Less_Than_0": "La cantidad no puede ser menor que 0",
    "quantity_of_SKU_replacements": "Cantidad de reemplazos de SKU",

    "Suspend_the_responsible_party_from_the_warehouse": "Suspender la parte responsable de la salida del almacén",
    "Delivery_omission": "Omisión en entrega al almacén",
    "Cancel_intercept": "Cancelar intercepción",
    "Cancel_delivery_omission": "Cancelar omisión en entrega",
    "client": "Cliente",
    "staff": "Personal",
    "reason": "Razón",
    "Cancel_intercept_processing": "Cancelando intercepción en proceso",
    "Cancel_intercept_successfully": "Intercepción cancelada con éxito",
    "Failed_to_cancel_intercept": "Fallo en el cancelación de la intercepción",
    "Delivery_missing_processing": "Procesando omisión en entrega al almacén",
    "Delivery_of_missing_processing_completed": "Procesamiento de omisión en entrega al almacén completado",
    "Failed_to_send_warehouse_missing_processing": "Fallo en el procesamiento de omisión en entrega al almacén",
    "Cancel_delivery_missing": "Cancelando omisión en entrega al almacén",
    "Cancel_delivery_missing_complete": "Cancelación de omisión en entrega al almacén completa",
    "Failed_to_cancel_delivery_omission": "Fallo en el cancelación de omisión en entrega",
    "Setting_an_account_failed": "Fallo en la configuración de la cuenta de salida",
    "Set_the_account_successfully": "Configuración de la cuenta de salida realizada correctamente",
    "Change_the_shipment_number": "Cambiar el número de envío para cambiar etiquetas",
    "Shipment_number_information": "Información del número de envío",
    "Charge_Off": "Carga fuera (contabilización)",
    "Set_up_an_account": "Configurar cuenta de salida",
    "Number_of_delivery_pieces": "Número de piezas entregadas",
    "Not_Set": "No configurado",
    "Outstanding_account": "Cuenta pendiente (aún no cargada)",
    "Out_of_account": "Cuenta cargada/liquidada",
    "Check_SKU": "Ver SKU",
    "Change_mark_or_not": "¿Cambiar etiqueta?",
    "Total_number_of_label_changes": "Total de cambios de etiqueta",
    "Actual_number_of_label_changes": "Número real de cambios de etiqueta",
    "Number_of_repeated_label_changes": "Número de cambios de etiqueta repetidos",
    "old_sku": "SKU antiguo",
    "new_sku": "SKU nuevo",
    "allNum": "Cantidad total",
    "Label_the_outer_box_repeatedly": "Etiquetar repetidamente la caja exterior",
    "Cancel_the_repeated_labeling_of_the_outer_box_label": "Cancelar la etiquetado repetido de la caja exterior",
    "Whether_to_set_repeat_replacement": "¿Configurar reemplazo repetido?",
    "The_outer_box_label_does_not_actually_change_the_label": "La etiqueta de la caja exterior realmente no cambia la etiqueta",
    "Cancel_the_outer_box_label_actually_do_not_change_the_label": "Cancelar la opción de que la etiqueta de la caja exterior realmente no cambia la etiqueta",
    "Set_the_actual_SKU_replacement_information": "Configurar la información de reemplazo real del SKU",
    "If_the_label_has_been_changed_before,_set_the_actual_number_of_label_changes": "Si la etiqueta ya ha sido cambiada antes en el pedido de salida, por favor configure el número real de etiquetas cambiadas",
    "Label_change_repeatedly": "Cambio de etiqueta repetido",
    "Repeated_switching": "Cambio de etiqueta repetido",
    "No_repeated_label_change": "Sin cambio de etiqueta repetido",
  },
  order: {
    "exchange_number": "número de cambio",
    "cargo_departure_time": "hora de salida de carga",
    "cargo_exit_hold": "bodega de salida de carga",
    "charge_output_state": "estado de salida de carga",
    "type_of_transport": "tipo de transporte",
    "pick_up_order_number/container_number": "número de recoger pedido/número de contenedor",
    "shipping_time": "hora de envío",
    "status_of_receiving_products": "estado de recibir productos",
    "cargo_access_code": "código de ingreso de carga",
    "load_exit_code": "código de salida de carga",
    "time_to_remove_containers": "hora de retirar contenedores",
    "cargo_entry_time": "hora de ingreso de carga",
    "warehouse_management": "manejo de almacén",
    "old_reserve_area": "área de reserva antiguo",
    "reserve_area_now": "área de reserva ahora",
    "secondary_shipment_number": "número de envío secundaria",
    "new_secondary_shipping_number": "número de envío secundaria nueva",
    "shipping_new/label": "envío nuevo/etiqueta",
    "length": "longitud",
    "width": "ancho",
    "height": "altura",
    "export_sheet": "exportar hoja",
    "export_outer_box_label": "exportar etiqueta de caja exterior",
    "export_corresponding_secondary_shipping_sheet": "exportar hoja de envío secundaria correspondiente",
    "export_corresponding_secondary_shipment/outer_box_label": "exportar envío secundaria correspondiente/etiqueta de caja exterior",
    "delivery_number": "número de envío",
    "shipping/outer_box_label": "envío/etiqueta de caja exterior",
    "export_data": "exportar datos",
    "filter": "filtro",
    "reboot": "reinicio",
    "total_pieces/piece": "piezas totales/pieza",
    "total_volume/cubic_meters": "volumen total/metros cúbicos",
    "total_weight/kg": "peso toal/kilogramo",
    "state": "estado",
    "handling": "manejo",
    "Check": "revisar",
    "volume/cubic_meters": "volumen/metros cúbicos",
    "weight/kg": "peso/kilogramo",
    "total_pieces": "piezas totales",
    "total_weight": "peso total",
    "total_volume": "volumen total",
    "piece": "pieza",
    "Company_logo": "Empresa logo",
    "Cargo_status": "Estado de mercancías"
  },
  container: {
    "Print_partition_code": "Código de la partición de la impresión",
    "add_new_container": "agregar recipiente nuevo",
    "add": "agregar",
    "delivery_number": "número de envío",
    "container_code": "código de recipiente",
    "secondary_shipping/secondary_outer_carton_label": "envío secundaria/etiqueta de caja exterior secundaria",
    "volume": "volumen",
    "edit": "editar",
    "delete": "eliminar",
    "close": "cerrar",
    "discharge": "descargar",
    "Cancel": "cancelar",
    "confirm_on_delete_container": "confirmar en eliminar recipiente ",
    "edit_container": "editar recipiente",
    "container_name": "nombre de recipiente",
    "save": "guardar ",
    "export_data": "exportar datos",
    "filter": "filtro",
    "reboot": "reinicio",
    "total_pieces": "piezas totales",
    "related_shipping_quantity/pcs": "cantidad de envío relacionado/piezas",
    "secondary_shipment_number": "número de envío secundaria",
    "cargo_access_code": "código de ingreso de carga",
    "piece": "pieza",
    "print_container_code": "Imprimir código de envse",
    "select_all": "Selecionar todo",
    "Container_log": "Logs de contenedores",
    "batch_delete": "Eliminación de lote",
    "Transfer_record": "Registro de transferencia",
    "Large_area_code": "Código de área grande",
    "pre_transfer": "pre-transferencia",
    "post_transfer": "post-transferencia",
  },
  abnormalData: {
    "delivery_number": "número de envío",
    "driving_type": "tipo de manejo",
    "cargo_entry_time": "hora de ingreso de carga",
    "shipping/outer_box_label": "envío/etiqueta de caja exterior",
    "Operating_deadline": "fecha límite de manejo",
    "pieces_uploaded_without_scanning": "piezas subidas sin escaneo",
    "paste_label": "pegar etiqueta",
    "customers": "clientes",
    "Salesman": "vendedor",
    "secondary_shipment_number": "número de envío secundaria",
    "product_label": "etiqueta de productos",
    "load_output_number": "número de salida de carga",
    "cargo_departure_time": "hora de salida de carga",
    "time_to_remove_containers": "hora de retirar contenedores",
    "related_shipping_quantity/pcs": "cantidad de envío relacionado/piezas",
    "Warehouse_output_quantity/pieces": "cantidad de salida de bodega/piezas",
    "amount_of_strange": "cantidad de extraño",
    "successful_scan_parts": "piezas de escaneo exitoso",
    "parts_without_scanning": "piezas sin escaneo",
    "person_responsible_for_shipping": "persona responsable de envío",
    "shipping_car_number": "número de coche de envío",
    "secondary_shipping/secondary_outer_carton_label": "envío secundaria/etiqueta de caja exterior secundaria",
    "quantity_of_products/pieces": "cantidad de productos/piezas",
    "cargo_entry_number": "número de ingreso de carga",
    "warehouse_receipt_amount/piece": "cantidad de ingreso de bodega/pieza",
    "pieces_of_strange": "piezas de extraño",
    "pieces_without_charge_entry": "piezas sin ingreso de carga",
    "type_of_transport": "tipo de transporte",
    "pick_up_order_number/container_number": "número de recoger pedido/número de contenedor",
    "Data_export": "Exportar datos",
    "filter": "Filtro",
    "reset": "Reiniciar",
    "piece": "/Pieza",
    "Total_volume": "Volumen total",
    "Total_weight": "Peso total",
    "driving": "Manejo",
    "view": "Chequear",
    "out_warehouse_number": "Número de hoja de salida",
    "title": "Título",
    "status": "Estado",
    "Total": "Piezas totales",
    "reject_number": "Piezas rechazadas",
    "intercept_number": "Piezas detenidas",
    "creator": "Fundador",
    "address": "Dirección",
    "create_time": "Tiempo de creación",
    "delivery_time": "Tiempo de enviar cajas a bodega",
    "remark": "Nota",
    "delete": "Eliminar",
    "Are_you_sure_to_delete": "Seguro que eliminar?",
    "cancel": "Cancelar",
    "waiting_for_out_warehouse": "Pendiente de salida",
    "warehousing": "Está saliendo",
    "warehoused": "Se han salido ",
    "out_warehouse_abnormally": "Salida anormal de carga",
    "deleting": "Está eliminando ",
    "Successfully_deleted": "Eliminar exitosamente",
    "Failed_to_get_out_warehouse_list": "Falsamente obtener lista de salida",
    "Deletion_failure": "Eliminar falsamente",
    "Scan_list": "Lista de escaneo",
    "batch_delivery": "Salida por lotes",
    "out_warehouse_intercept": "Salida detenida",
    "Are_you_sure_to_out_warehouse?": "Seguro en salida?",
    "File_list": "Lista de archivos",
    "batch_download": "Descargar por lotes",
    "take_a_photo_and_send_back": "Tomar foto y devolverla ",
    "download": "Descargar",
    "close": "Cerrar",
    "Reject_list": "Lista de rechazo",
    "download_attachment": "Descargar anexo",
    "edit": "Editar",
    "update_attachment": "Actualizar anexo",
    "print_out_warehouse_list": "Imprimir hoja de salida",
    "export_out_warehouse_list": "Exportar hoja de salida",
    "Export_the_reject_list": "Exportar lista de rechazo",
    "all": "Todo",
    "Scanned": "Escaneo hecho",
    "To_be_scanned": "Pendiente de escaneo",
    "rejection": "Rechazo",
    "Failed_to_get_out_warehouse_details": "Falsamente obtener detalles de salida ",
    "Failed_to_get_scan_list": "Falsamente obtener lista de escaneo",
    "Please_check_first": "Marque primero",
    "Batch_delivery_successfully": "Salida exitosa por lotes",
    "Batch_out_warehouse_unsuccessfully": "Salida falso por lotes",
    "Failed_to_get_fileList": "Falsamente obtener lista de archivo",
    "File_is_empty": "Archivo está vacio",
    "Download_unsuccessfully": "Descarga falsa",
    "Download_successfully": "Descarga exitosa",
    "The_attached_file_is_empty": "Archivo de anexo está vacio",
    "exporting": "Está exportando",
    "Export_successfully": "Exportación exitosa",
    "Export_unsuccessfully": "Exportación falsa",
    "intercept_reason": "Razón detenida",
    "intercepting": "Se está impidiendo la salida",
    "Intercept_successfully": "Exitosamente se impidió la salida",
    "Edit_reject_reason": "Editar razón de rechazo",
    "reject_reason": "Razón de rechazo",
    "editing": "Están editando",
    "Edit_successfully": "Editar exitosamente",
    "Edit_failure": "Editar falsamente",
    "Update_attachment": "Actualizar anexo",
    "Please_upload_the_attached_file": "Suba archivo de anexo por favor",
    "Only_xls,_xlsx,_PDF_files_are_supported": "Solo apoyo para archivo xls, xlsx, PDF",
    "updating": "Está actualizando",
    "Update_successfully": "Actualizar exitosamente",
    "Intercept_unsuccessfully": "Falsamente se impidió la salida",
    "Failed_to_get_warehousing_list": "Falsamente obtener lista de ingreso",
    "warehoused_number": "Piezas de carga ingresada ",
    "volume": "Volumen total/metros cúbicos",
    "weight": "Peso/kilogramo",
    "Failed_to_get_warehousing_details": "Falsamente obtener detalles de ingreso",
    "confirm": "Sí",
    "save": "Guardar",
    "Intercepted_number_of_out_warehouse": "Piezas detenidas de salida",
    "Warehouse_area": "Área de almacén",
    "uploader": "La persona que lo subió",
    "Upload_time": "Tiempo de carga",
    "picture": "fotografía",
    "TC_sub-order_number": "Número subúnico de CT",
    "secondary_shipping": "Número de envío para niños",
    "modifier": "Modificar a la persona",
    "modifier_time": "Tiempo de modificación",
    "edit_order_number": "Editar el número de seguimiento",
    "please_enter_the_sub-order_number": "Introduce el número de subpedido",
    "please_enter_the_secondary_shipping": "introduce el número de subenvío",

  },
  baseSetting: {
    "add": "agregar",
    "brand": "marca",
    "boss/responsible_person": "jefa/persona responsable",
    "administrator": "administrador",
    "edit": "editar",
    "Delete": "eliminar",
    "Sure_to_delete_car_information?": "seguro que eliminar la información de coche?",
    "Confirm": "confirmar",
    "Cancel": "cancelar",
    "add_car_info": "añadir información de coche",
    "car_number": "número de coche",
    "Car_type": "tipo de coche",
    "Purchased_time": "hora de compras",
    "all_people/everyone": "todas las personas/todo el mundo",
    "regulator/administrator": "regulador/administrador",
    "save": "guadar",
    "edit_car_info": "editar información de coche",
    "contact": "contacto",
    "phone_contact": "contacto de teléfono",
    "detailed_address": "dirección detallada",
    "add_classification_area": "añadir área de clasificación",
    "secondary_sorting_quantity/pieces": "cantidad de clasificación secundaria/piezas",
    "number_of_pallets/pieces": "cantidad de tarima/piezas",
    "maximum_capacity/cubic_meters": "capacidad máxima/metros cúbicos",
    "sure_to_delete_area_F?": "seguro de eliminar área F?",
    "classification_name": "nombre de clasificación",
    "maximum_capacity": "capacidad máxima",
    "edit_warehouse_information": "editar información de bodega",
    "warehouse_name": " numbre de bodega",
    "secondary_classification_area": "área de clasificación secundaria",
    "add_secondary_sort_area": "añadir área de clasificación secundaria",
    "location_code": "código de ubicación",
    "capacity_now": "capacidad ahora",
    "Carboard": "tarima",
    "Add_carboard": "añadir tarima",
    "Carboard_code": "código de tarima",
    "Edit_carboard": "editar tarima",
    "Carboard_name": "nombre de tarima",
    "edit_secondary_sort_area": "editar área de clasificación secundaria",
    "secondary_sort_area_name": "nombre de área de clasificación secundaria",
    "close": "cerrar",
    "download": "descargar",
    "Total_number_of_pieces": "Número total de piezas",
    "shipping": "envío",
    "delivery_number": "número de envío",
    "secondary_shipment_number": "número de envío secundaria",
    "sure_to_delete": "seguro de eliminar",
    "area": "área",
    "Number_of_subarea": "cantidad de área clasificada/piezas",
    "Edit_subarea": "editar área clasificada",
    "Old_shipment_number": "número de envío viejo",
    "scanning_time": "fecha de escaneo",
    "shelf": "Estantes",
    "Add_shelf": "Añadir estantes",
    "Export_the_required_location_code_size": "Exportar las dimensiones del código de ubicación necesarias",
    "Export": "Exportar",
    "Number_of_layers": "Número de capas",
    "Add_layers": "Agregar el número de capas",
    "location": "Ubicación del contenedor",
    "Add_location": "Agregar una ubicación de contenedor",
    "Failed_to_get_the_warehouse_shelf_list": "Error al obtener la lista de estantes del almacén",
    "Failed_to_get_the_layer_number_of_warehouse_list": "Error al obtener la lista de niveles de almacén",
    "Failed_to_get_the_warehouse_location_list": "Error al obtener la lista de ubicaciones de almacén",
    "print": "Imprimir",
    "Select_All": "Seleccionar todo",
    "All_complete": "Seleccionar todo para completar",
    "Please_select_the_print_item_first": "Por favor, compruebe primero el elemento impreso",

  },
  deliveryReservation: {
    "Daily_Reservation": "Reserva al día ",
    "Last_month": "el mes pasado",
    "today": "hoy",
    "Next_month": "el mes que viene",
    "Reservation_status": "proceso de reserva",
    "piece": "pieza",
    "Daily_delivery": "envío a bodega cada día",
    "Delivery_instructions": "Instrucciones de bodega",
    "Reservation_time": "tiempo de reserva",
    "Delivery_time": "tiempo de envío ",
    "Shipment_number/shipping_mark": "número de envío/etiqueta de caja exterior",
    "pieces": "piezas",
    "Cancel_delivery": "cancelar envío",
    "Confirm_whether_to_cancel_delivery": "seguro en cancelar envío?",
    "Successfully_cancel_delivery": "Cancelar envío exitosamente",
    "Failed_to_cancel_delivery": "Cancelar envío falsamente",
    "Failed_to_get_data": "Falsamente obtener datos",
    "export": "Exportar",
    "exporting": "Está exportando",
    "Export_successfully": "Exportar exitosamente",
    "Export_unsuccessfully": "Exportar falsamente",
    "delivery_number": "número de envío",
    "shipping/outer_box_label": "envío/etiqueta de caja exterior",
    "load_output_number": "número de hoja de salida",
    "filter": "Filtro",
    "reset": "Reiniciar",
    "shipping": "envío",
    "driving": "Manejo",
    "confirm": "Sí",
    "cancel": "Cancelar",

  },
  workorder: {
    "Work_order_number": "Número de billete",
    "Associated_order_number": "Número de seguimiento asociado",
    "Operating_state": "Estado de la operación",
    "work_order_node": "Nodo de billete",
    "Work_order_type": "Tipo de billete",
    "Problem_type": "Tipo de problema",
    "Creation_time": "Hora de creación",
    "creator": "Fundador",
    "Processing_department": "Departamento de Procesamiento",
    "filter": "Cernir",
    "reset": "Reconstituir",
    "customer_code": "Código de cliente",
    "Current_processing_department": "Departamento de procesamiento actual",
    "Current_processor": "Procesador actual",
    "Associated_work_order": "Tickets de asociados",
    "Current_node_time": "El nodo actual ha transcurrido",
    "Total_time_spent": "Tiempo total consumido",
    "Claim_time": "Tiempo de reclamo",
    "Prescription_of_claim": "Estatuto de limitaciones de la reclamación",
    "Operating_time": "Tiempo de operación",
    "Description": "Describir",
    "remark": "Notar",
    "Latest_communication_content": "Comunicaciones actualizadas",
    "view": "Examinar",
    "All": "Total",
    "Wait_to_be_done": "Próximo",
    "Have_done": "Fecho",
    "My_creation": "Mi creación",
    "Copy_me": "Enviado a mí",
    "Withdrawn": "Retirado",
    "rejected": "Rechazado",
    "submitted": "Presentado",
    "finished": "Finalizado",
    "Obsolete": "Obsolescente",
    "Have_already_been_created": "Creado",
    "In_process": "En procesamiento",
    "Failed_to_get_a_CC": "Error de envío",
    "Failed_to_get_the_work_order_type_list": "Error al obtener la lista de tipos de tickets",
    "Failed_to_get_the_problem_type_list": "Error al obtener la lista de tipos de problemas",
    "Failed_to_get_the_department_list": "Error al obtener la lista de departamentos",
    "Failed_to_get_the_employee_list": "Error al obtener la lista de empleados",
    "Failed_to_get_the_work_order_list": "Error al obtener obtener la lista de boletos",
    "Failed_to_change_cc_status": "Error al enviar el cambio de estado",
    "Basic_information": "Información básica",
    "View_the_change_mark_requirements": "Revisar los requisitos de reetiquetado",
    "Attached_file": "Documentos adjuntos",
    "Attached_picture": "Fotos adjuntos",
    "Processing_information": "despahca información",
    "Whether_to_view": "Si ver o no",
    "Owning_warehouse": "Almacén al que pertenece",
    "Communication_information": "Comunicar información",
    "Communication_reply": "Responder información",
    "issuer": "expedidor",
    "Carbon_copy_recipient": "La persona notificada",
    "Communication_content": "Contenido de la comunicación",
    "Claim_the_work_order": "Reclama tickets",
    "Processing_work_order": "Procesar tickets",
    "Work_order_processing_log": "Registros de procesamiento de tickets",
    "unfold": "desplegar",
    "handle": "despachar",
    "Pack_up": "colapsar",
    "processor": "Controladores",
    "Check_the_time": "Consulta la hora",
    "Node_time_consuming": "El tiempo que tarda el nodo",
    "Processing_remarks": "Trabajar con notas",
    "Are_you_sure_you_want_to_claim_a_work_order?": "¿Está seguro de que desea reclamar un boleto?",
    "confirm": "definir",
    "Cancel": "cancelar",
    "Are_you_sure_you_want_to_complete_a_work_order?": "¿Estás seguro de que quieres cerrar tu ticket?",
    "source": "origen",
    "Whether_internal_work_order": "Si es o no un ticket interno",
    "initiator": "iniciador",
    "Commit_processing": "Envío para procesamiento",
    "Finished_work_order": "Ticket de trabajo completa",
    "Withdrawal_processing": "Retirada del tratamiento",
    "Scrap_ticket": "Tickets de trabajo abandandos",
    "Failed_to_get_domain_name": "Error al obtener el nombre de dominio",
    "Failed_to_get_details": "Error al obtener detalles",
    "Failed_to_get_chat_list": "Error al  obtener la lista de chat",
    "Failed_to_get_the_log_list": "Error al obtener la lista de registros",
    "Reason_for_withdrawal": "Motivo del desistimiento",
    "Cause_of_rejection": "Motivo del rechazo",
    "Reason_for_abandonment": "Motivo del abandono",
    "Claiming": "Reclamando",
    "Claim_successfully": "Reclamación exitosa",
    "Failed_to_claim_the_work_order": "Erro al reclamar el boleto",
    "In_completion": "completando",
    "Complete_success": "Completado con éxito",
    "Failed_to_complete_the_work_order": "Error al cerrar el ticket",
    "Number_of_packages": "Número ",
    "Shipping_slip_number": "Número de albarán",
    "Old_shipment_number": "Número de envío antiguo",
    "Shipping_mark": "Marcas",
    "New_shipment_number": "Nuevo número de envío",
    "Old_SKU_logo": "Antigua etiqueta de SKU",
    "New_SKU_logo": "Nueva etiqueta de SKU",
    "Please_fill_in": "Por favor, rellene",
    "required": "Obligatorio",
    "Picture_upload": "Subir foto",
    "Only_support": "Solo compatible",
    "isoformat": "y otros formatos de imagen",
    "Being_submitted": "En presentación",
    "Submit_successfully": "Presentación exitosa",
    "Commit_failure": "Presentación fallida",
    "Processing_result": "Procesar los resultados",
    "Failed_to_obtain_the_service_provider_list": "Error al obtener la lista de proveedores de servicios",
    "task_code": "Código de tarea"

  },
  error: {
    "Sorry_the_page_you_are_looking_for_is_missing": "Lo sentimos, faltaba la página que intentabas visitar",
    "To_check_whether_the_URL_you_entered_is_correct_please_click_the_button_below_to_return_to_the_home_page": "Compruebe que ha introducido la URL correcta, haga clic en el botón de abajo para volver a la página de inicio",
    "Back": "Regresar"
  },
  other: {
    "batch_intercept": " Intercepción por lotes",
    "view_excel_content": "  Ver contenido de Excel",
    "tc_order_number": "Número de pedido de TC",
    "tc_order_id": "ID de pedido de TC",
    "Shipments_sent_to_Full_or_FBA": "Envíos enviados al Full o FBA",
    "change_mark": "Marca de cambio",
    "hour": "Hora",
    "Export_location_information_(trial)": "Exportar información de ubicación (versión de prueba)",
    "have_joined_downlod_queue": "Se han unido a la cola de Downlod",
    "Edit_information": "Editar información",
    "Confirm_the_changes?": "¿Confirmar los cambios?",
    "Please_enter_search_criteria": "Por favor, introduzca los criterios de búsqueda",
    "search": "buscar",
    "return_claim": "Reclamación de devolución",
    "return_code": "Código de retorno",
    "Shipment_number": "Número de envío",
    "export_multi_subarea_outwarehouse_order_list": "Exportar varias subáreas Lista de pedidos de Outwarehouse",
    "secondary_shipment/outer_box_label": "Envío secundario/etiqueta de caja exterior",
    "pcs": "Pc",
    "Failed_to_get": "No se pudo obtener",
    "Please_enter_the_license_plate_number": "Por favor, introduzca el número de matrícula",
    "Please_select_the_purchase_time": "Por favor, seleccione la hora de compra",
    "Are_you_sure_to_add_another_layer_to_this_shelf?": "¿Está seguro de agregar otra capa a este estante?",
    "Are_you_sure_to_add_a_storage_location_on_this_shelf?": "¿Está seguro de agregar una ubicación de almacenamiento en este estante?",
    "Are_you_sure_to_delete_this_shelf?": "¿Está seguro de eliminar este estante?",
    "Are_you_sure_to_delete_the_maximum_number_of_shelves?": "¿Está seguro de eliminar el número máximo de estanterías?",
    "Are_you_sure_to_delete_this_storage_location?": "¿Está seguro de eliminar esta ubicación de almacenamiento?",
    "Shelf_name": "Nombre del estante",
    "the_number_of_columns_of_location": "El número de columnas de la ubicación de almacenamiento",
    "single": "solo",
    "batch": "lote",
    "Add_a_new_container": "Agregar un nuevo contenedor",
    "Please_enter_the_container_code": "Introduzca el código del contenedor ",
    "The_container_code_is_too_long_Please_re_enter_it": "El código contenedor es demasiado largo. Por favor, vuelva a introducirlo",
    "Add_new_containers_in_batches": "Agregar nuevos contenedores en lotes",
    "quantity": "Cantidad",
    "Please_enter_quantity": "Introduce la cantidad",
    "warehouse": "Almacén",
    "uploading": "Cargando",
    "upload_successfully": "Cargar exitosamente",
    "out_warehouse": "Se despachan del almacén",
    "Sure_out_warehouse?": "¿Se afirman despachar?",
    "Dispatching": "Despachándose",
    "Successfully_dispatched_from_storage": "Se despacharon del almancén exitosamente",
    "Dispatched_from_the_warehouse_unsuccessfully": "Se despacharon del almancén fallidamente",
    "Reject_the_ticket": "Rechazar el ticket",
    "If_final_processing_is_complete,_select_the_processor_to_make_the_response_(required)": "Si se completa el procesamiento final, seleccione el encargado prara hacer la repuesta (obligatorio)",
    "Interior": "Interior",
    "Outside": "Exterior",
    "Cargo_information": "Información sobre mercancías",
    "Export_Cargo_information": "Información sobre carga de exportación",
    "inventory_anomaly": "Anomalanomalen el inventario",
    "reprint": "reimpresión",
    "twice": "Dos veces",
    "Batch_update_title": "Actualizar título por lotes",
    "No_file": "Sin archivos por el momento",
    "batch_delete": "Eliminar en lotes",
    "You_have_selected": "Has seleccionado",
    "pieces_of_document_information_Are_you_sure_you_want_to_delete_them": "información de documentos, ¿seguro que quieres eliminarlos?",
    "In_operation": "En operación",
    "Batch_deletion_succeeded": "Eliminación en lotes exitosa",
    "Batch_deletion_failed": "Error al eliminar en lotes",
    "sku_code": "Código SKU",
    "customer_name": "Nombre del cliente",
    "time": "Tiempo",
    "driving": "Operación",
    "view": "Ver",
    "SKU_quantity_*_Unit_price": "Cantidad de SKU * Precio unitario",
    "fail_to_get": "Error al obtener",
    "Picking_situation": "Situación de selección de mercancía",
    "date": "Fecha",
    "abnormal_condition": "Condición anormal",
    "total_number_of_packages": "Número total de paquetes",
    "Pick_volume_in_the_last_seven_days": "Volumen de selección de mercancía en los últimos siete días",
    "line_chart": "Gráfico de líneas",
    "histogram": "Histograma",
    "Number_of_pending_orders": "Número de órdenes pendientes de selección",
    "Order_quantity_in_picking": "Cantidad de órdenes en selección",
    "Quantity_shipped_out_today": "Cantidad despachada hoy",
    "Refuse_to_return_to_warehouse": "Rechazar devolución al almacén",
    "Number_of_unpacked_shelves": "Número de estantes desembalados",
    "Quantity_of_original_package_transfer": "Cantidad de transferencias de paquetes originales",
    "Pick_list_number": "Número de lista de selección",
    "type": "Tipo",
    "generated_time": "Tiempo de generación",
    "creation_time": "Tiempo de creación",
    "order_quantity": "Cantidad de órdenes",
    "Total_number_of_SKUs": "Número total de SKU",
    "filter": "Filtrar",
    "reset": "Restablecer",
    "Print_the_pick_list": "Imprimir la lista de selección",
    "Are_you_sure_you_want_to_generate_a_pick_list": "¿Seguro que quieres generar una lista de selección"
  },
  loadingLot: {
    "delivery_number": "número de envío",
    "shipping": "envío",
    "creator": "Fundador",
    "End_time_of_scanning_code": "Tiempo final del código de barrido",
    "car_number": "número de coche",
    "filter": "Filtro",
    "reset": "Reiniciar",
    "driving": "Manejo",
    "view": "Examinar",
    "batch_export": "Exportar en lotes",
    "lot_number": "Número de lote",
    "Number_of_packages": "Número",
    "Create_time": "Tiempo de creación",
    "export_data": "exportar datos",
    "lot_information": "Información del lote",
    "total_pieces": "piezas totales",
    "Code_scanner": "Barrido de código hombre",
    "Shipment_information": "Información de piezas",
    "Code_scanning_information": "Información del código de barrido",
    "Code_scanning_failure_information": "Mensaje de error del código de barrido",
    "tc_order_number": "Número de pedido de TC",
    "Shipment_number": "Número de envío",
    "Number_of_items_to_be_shipped_out": "Número de piezas que deben salir del almacén",
    "Number_of_scanned_codes": "Número de piezas de barrido",
    "Missing_pieces": "Falta número de piezas",
    "Missing_number": "Número que falta",
    "TC_sub-order_number": "Número subúnico de CT",
    "secondary_shipping": "Número de envío para niños",
    "scanning_time": "fecha de escaneo",
    "Cause_of_failure": "Causas del fracaso",
    "transport_car_no": "Matrícula del vehículo",
    "create_user_realname": "Creador",
    "create_time": "Fecha de creación",
    "number": "Número de lote",
    "scan_count": "Número de documentos escaneados"
  },
  returnGoods: {
    "Generate_a_single_barcode": "Generar un código de barras único",
    "Batch_barcode_generation": "Generación de código de barras por lotes",
    "Shipment_number/shipping_mark": "Número de envío/marca de envío",
    "Input_example": "Ejemplo de entrada",
    "Please_enter": "Por favor introduzca",
    "Start_number": "Número de inicio",
    "End_number": "Número final",
    "Shipping_mark_delimiter": "Marca de envío delimiter",
    "If_the_number_is_set_to_1-10,_it_will_be_generated_in_the_form_of_12345-1_to_12345-10": "Si el número se establece en 1-10, se generará en la forma de 12345-1 a 12345-10",
    "Cancel": "cancelar",
    "print": "imprimir",
    "Export": "exportación",
    "None": "ninguno",
    "saving": "ahorro",
    "printing": "Impresión de impresión",
    "Print_successfully": "Impresión con éxito",
    "Failed_to_print": "No se ha podido imprimir",
    "exporting": "exportación",
    "Export_successfully": "Exportar con éxito",
    "Failed_to_export_the_sheet": "No pudo exportar la hoja",
    "Platform_type": "Tipo de plataforma",
    "Return_code": "Código de retorno",
    "Number_of_packages": "Número de paquetes",
    "Add": "añadir",
    "Fill_in_optional": "Completar opcional",
    "Shipment_number": "Número de envío",
    "Delete": "eliminar",
    "Return_goods": "Devolución de mercancías",
    "United_States_and_Mexico": "Estados Unidos y México",
    "Saving": "ahorro",
    "Save_successfully": "Guardar correctamente",
    "Failed_to_save": "No ha podido ahorrar",
    "Failed_to_obtain_the_platform_type_list": "No se pudo obtener la lista de tipos de plataforma",
    "Please_select_the_platform_type!": "Por favor seleccione el tipo de plataforma!",
    "Failed_to_add_the_shipment_number": "No se pudo agregar el número de envío",
    "The_return_code_can_only_be_added_once": "El código de retorno sólo se puede añadir una vez",
    "Please_enter_the_correct_information!": "Por favor, introduzca la información correcta!",
    "adding": "añadir",
    "Add_successfully": "Añadir correctamente",
    "Add_failure": "Fallo adicional",
    "Return/US-Mexico_details": "Return/US-Mexico details (en inglés)",
    "TC_order_number": "Número de pedido TC",
    "Founder": "fundador",
    "Warehousing_time": "Tiempo de almacenamiento",
    "Total_number_of_packages": "Número Total de paquetes",
    "Order_type": "Tipo de pedido",
    "Time_of_creation": "Tiempo de creación",
    "Order_information": "Información de pedido",
    "TC_sub-order_number": "Número de suborden del TC",
    "Sub-shipment_number": "Número de subenvío",
    "Express_tracking_number": "Número de seguimiento Express",
    "Storage_location_information": "Información de ubicación de almacenamiento",
    "The_latest_update_time": "El último tiempo de actualización",
    "Updater": "actualización",
    "Cargo_picture": "Imagen de carga",
    "Print_sheet": "Printhitt",
    "Export_sheet": "Éxito de exportación",
    "Download": "Don Señor",
    "Close": "Cruz",
    "Failed_to_get_details": "Feld Togert De Tails",
    "Failed_to_obtain_form": "Feldto Obtanfom",
    "generate": "Genirat",
    "Express_Ticket_number": "Exprestik Denunbo",
    "New_order": "Nuevoviejo",
    "Type_of_order": "Tapben Odd",
    "Type_of_shipment": "Envío Taipuben",
    "Generate_barcode": "Generar código de barras",
    "View_size": "El tamaño de la vista",
    "resize": "Cambiar el tamaño",
    "Private_person": "Persona privada",
    "Address_template": "Plantilla de direcciones",
    "Upload_packing_list": "Cargar lista de embalaje",
    "Only_files_smaller_than_15_MB_are_supported": "Sólo los archivos menores de 15 MB son soportados",
    "Modify_size_information_in_batches": "Modificar la información del tamaño en lotes",
    "Gross_weight": "Peso bruto",

  },
  inventoryCenter: {
    "Edit_operation_time": "Tiempo de operación de edición",
    "Updated_in_Mexico_local_time": "Actualizado en hora local de México",
    "First_shelf_time_(manual)": "Tiempo de primera estan(manual)",
    "Takedown_time_(manual)": "Tiempo de retirada (manual)",
    "Exit_time_(manual)": "Tiempo de salida (manual)",
    "Warehousing_time_(manual)": "Tiempo de almacenamiento (manual)",
    "Shelf_time": "Tiempo de almacenamiento",
    "Update_the_outbound_time": "Actualizar el tiempo de salida",
    "Outbound_time_(Mexico_time)": "Tiempo de salida (hora México)",
  },
  transferWarehouse: {
    "order_number": "Número de pedido",
    "shipping_mark": "Marca de marca",
    "account_manager": "Administrador de cuentas",
    "reception_time": "Tiempo de recepción",
    "estimated_time_of_delivery": "Tiempo estimado de entrega",
    "order_status": "Estado del pedido",
    "audit_status": "Estado de la auditoría",
    "filter": "Filtro de filtro",
    "reset": "restablecer",
    "operate": "operan",
    "view": "ver",
    "pending": "Pendiente pendiente",
    "audited": "audiaudiaudiaudi",
    "failed_to_get": "El presidente",
    "saving": "ahorro",
    "saved_successfully": "Guardado con éxito",
    "failed_to_save": "No ha podido ahorrar",
    "order_information": "Información de pedido",
    "number_of_pieces": "Número de piezas",
    "creation_time": "Tiempo de creación",
    "shelf_start_time": "Tiempo de inicio",
    "storage_age": "Edad de almacenamiento",
    "finish_shelf_time": "Tiempo de finalización de la estande",
    "place_of_dispatch": "Lugar de expedición",
    "total_weight": "Peso total",
    "receiver": "receptor",
    "destination_warehouse": "Almacén de destino",
    "total_volume": "Volumen total",
    "customer_information": "Información del cliente",
    "customer_name": "Nombre del cliente",
    "contact_person": "Persona de contacto",
    "contact_number": "Número de contacto",
    "shipment_information": "Información sobre envíos",
    "total_actual_weight": "Peso Total efectivo",
    "total_volume(volume_weight)": "Volumen Total (peso en volumen)",
    "total_square(volume)": "Total del cuadrado (volumen)",
    "total_square(actual_weight_divided_by_300)": "Total del cuadrado (peso actual dividido por 300)",
    "total_quantity": "Cantidad Total",
    "shipment_status": "Estado del envío",
    "days_of_storage": "Días de almacenamiento",
    "hide": "ocultar",
    "expand": "ampliar",
    "tc_subtracking_number": "Número de subseguimiento de TC",
    "sub_shipment_number/shipping_mark": "Número de envío/marca de envío",
    "length_*_width_*_height_/cm": "Longitud * anchura * altura /CM",
    "gross_weight_/kg": "Peso bruto /KG",
    "scanned_picture": "La imagen escan",
    "scene_picture": "Cuadro de la escena",
    "sku_name": "SKU el nombre",
    "number_of_single_box": "Número de cajas individuales",
    "view_picture": "Ver la imagen",
    "receive_information": "Recibir información",
    "shipping_contact": "Contacto de envío",
    "shipping_contact_number": "Número de contacto",
    "receive_signature_picture": "Recibir la firma",
    "receive_picture": "Recibir la imagen",
    "repudiator": "repudiator",
    "rejection_time": "Tiempo de rechazo",
    "rejection_instructions": "Instrucciones de rechazo",
    "explanatory_picture": "Cuadro explicativo",
    "remark_information": "información",
    "customer_remarks": "Comentarios del cliente",
    "internal_remarks": "Observaciones internas",
    "print_sheet": "Hojas de impresión",
    "export_sheet": "Hojas de exportación",
    "export_warehouse_list": "Lista de almacenes para exportación",
    "export_customer_box_list": "Cuadro de exportación de clientes",
    "export_customer_service_box_list": "Exportar servicio al cliente box list",
    "export_attachment": "Derecho a la exportación",
    "off": "fuera",
    "download": "descargar",
    "new_sub_shipment_number/shipping_mark": "Nuevo número de envío/marca de envío",
    "change_type": "Cambio de tipo",
    "old_sub_shipment_number/shipping_mark": "Antiguo número de envío/marca de envío",
    "original_product_label": "Etiqueta Original del producto",
    "new_product_label": "Nueva etiqueta del producto",
    "quantity": "La cantidad",
    "change_area": "Zona de cambio",
    "uploading": "Cargar encima de carga",
    "upload_successfully": "Cargar correctamente",
    "failed_to_upload": "No se ha podido cargar",
    "removing": "retirar",
    "removed_successfully": "Eliminado con éxito",
    "failed_to_remove": "No se ha podido eliminar",
    "syncing": "sincronización",
    "sync_successfully": "Sincronización con éxito",
    "failed_to_sync": "No se pudo sincronizar",
    "no_data_available": "No hay datos disponibles",
    "exporting": "exportación",
    "warehouse_list": "Lista de almacén",
    "added_to_the_download_queue": "Añadido a la cola de descarga",
    "failed_to_export": "No export",
    "printing": "Impresión de impresión",
    "print_successfully": "Impresión con éxito",
    "failed_to_print": "No se ha podido imprimir",
    "piece": "La pieza",
    "number_of_packages": "Número de paquetes",
    "express_sheet": "Hojas expresexpresas",
    "reject_information": "Rechazar la información",
    "delivery_information": "Información de entrega",
    "operator": "operador",
    "operate_status": "Estado de funcionamiento",
    "process_time": "Tiempo de proceso",
    "picture": "Imagen de la imagen",
    "remark": "comentario",
    "accept": "aceptar",
    "reject": "rechazo",
    "picture_error,_please_contact_staff!": "Error de imagen, por favor póngase en contacto con el personal!"
  }
}