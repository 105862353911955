const serviceCenter = {
    namespaced: true,
    state: {
        copyMine: false,
        workSheetManageState: {},
    },
    getters: {
    },
    mutations: {
        setCopyMine(state, data) {
            state.copyMine = data
        },
        setWorkSheetManageState(state, data) {
            state.workSheetManageState = data
        },
    },
    actions: {

    }
}

export default serviceCenter