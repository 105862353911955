import Axios from "axios";
import $TcMsg from '@/utils/common/tc-message'
const axios = Axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    timeout: 600 * 1000, // 请求超时
    withCredentials: false // 跨越请求是否需要凭证
})

const apiSheLanguage = () => {
    let language_id = JSON.parse(localStorage.getItem("userInfo")) && JSON.parse(localStorage.getItem("userInfo")).language_id
    if (language_id == 1) return 'zh'
    if (language_id == 2) return 'en'
    if (language_id == 3) return 'es'
    return null
}

// 请求拦截器
axios.interceptors.request.use((config) => {
    // TODO: 发生请求前
    let authKey = localStorage.getItem("authKey") || ""
    let SHElanguage = localStorage.getItem("language") || apiSheLanguage() || ""
    let shemxversion = localStorage.getItem("shemxversion") || "0.0.0"
    config.headers["authKey"] = authKey
    config.headers["SHElanguage"] = SHElanguage
    config.headers["she-version-mx"] = shemxversion
    return config
}), (error) => {
    // TODO: 请求错误
    return Promise.reject(error)
}

// 响应拦截器
axios.interceptors.response.use((response) => {
    const { data: res } = response
    if (res.code == 0) {
        return Promise.resolve(res.data);
    }
    else if (response.status === 268 && response.config.responseType === 'blob') {
        let data = response.headers.shereturnmsg
        return Promise.reject({ error: eval("'" + data + "'") })
    }
    else if (response.status === 200 && response.config.responseType === 'blob') {
        return Promise.resolve(response)
    }
    else if (res.code == 302) {
        $TcMsg({ type: "error", text: "登录失效，请重新登录" })
        localStorage.removeItem("authKey")
        localStorage.removeItem("userInfo")
        location.reload("/login")
        return
    }
    else if (res.code == 308) {
        // 版本不匹配强制刷新
        localStorage.setItem('shemxversion', res.data.new_version)
        localStorage.removeItem("tagList")
        localStorage.removeItem("nowPath")
        location.reload()
        return
    }
    else {
        return Promise.reject(res)
    }

}), (error) => {
    return Promise.reject(error)
}

export default axios