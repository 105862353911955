import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_radio = _resolveComponent("el-radio");
  const _component_el_radio_group = _resolveComponent("el-radio-group");
  return _openBlock(), _createBlock(_component_el_radio_group, {
    modelValue: $setup.keyWord,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.keyWord = $event),
    class: "radio"
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$attrs.radioList, (item, index) => {
      return _openBlock(), _createBlock(_component_el_radio, {
        label: item.label,
        key: index
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(item.value), 1)]),
        _: 2
      }, 1032, ["label"]);
    }), 128))]),
    _: 1
  }, 8, ["modelValue"]);
}