import OperateTimeIcon from "@/assets/images/inventory-center/operate-time-icon.svg"
import WarehouseAgeIcon from "@/assets/images/inventory-center/warehouse-age-icon.svg"
import storageListIcon from "@/assets/images/inventory-center/storage-list-icon.png"

// 路由meta的title项 必须为 该多语言对应字段名

export default [
    {
        name: 'inventoryCenter',
        path: '/inventoryCenter',
        component: () => import('@/views/inventory-center'),
        children: [
            // {
            //     name: 'goodsAgeing',
            //     path: '/inventoryCenterIndex',
            //     component: () => import('@/views/inventory-center/goods-ageing'),
            //     redirect: '/inventoryCenter/goodsAgeing/index',
            //     meta: {
            //         title: 'goods_ageing',
            //         icon: WarehouseAgeIcon,
            //         isMulti: false,
            //     },
            //     children: [
            //         {
            //             name: 'goodsAgeingIndex',
            //             path: '/inventoryCenter/goodsAgeing/index',
            //             component: () => import('@/views/inventory-center/goods-ageing/goods-ageing-index'),
            //             meta: {
            //                 title: 'goods_ageing',
            //                 isShowing: true
            //             }
            //         }
            //     ]
            // },
            {
                name: 'operateTime',
                path: '/inventoryCenter/operateTime',
                component: () => import('@/views/inventory-center/operate-time'),
                redirect: '/inventoryCenter/operateTime/index',
                meta: {
                    title: 'operate_time',
                    icon: OperateTimeIcon,
                    isMulti: false,
                },
                children: [
                    {
                        name: 'operateTimeIndex',
                        path: '/inventoryCenter/operateTime/index',
                        component: () => import('@/views/inventory-center/operate-time/operate-time-index'),
                        meta: {
                            title: 'operate_time',
                            isShowing: true
                        }
                    }
                ]
            },
            {
                name: 'storageList',
                path: '/inventoryCenter/storageList',
                component: () => import('@/views/inventory-center/storage-list'),
                redirect: '/inventoryCenter/storageList/index',
                meta: {
                    title: 'storage_list',
                    icon: storageListIcon,
                    isMulti: false,
                },
                children: [
                    {
                        name: 'storageListIndex',
                        path: '/inventoryCenter/storageList/index',
                        component: () => import('@/views/inventory-center/storage-list/storage-list-index'),
                        meta: {
                            title: 'storage_list',
                            isShowing: true
                        }
                    }
                ]
            },
        ]
    }
]