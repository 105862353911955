import { createStore } from 'vuex'
import user from './modules/user'
import systemSetting from "./modules/system-setting"
import serviceCenter from "./modules/service-center"
import operateCenter from './modules/operate-center'
export default createStore({
  modules: {
    user,
    systemSetting,
    serviceCenter,
    operateCenter
  }
})
