/**
 * @param precision {boolean} 是否有小数点
 * @return  返回正整数字/字符串小数
 */
export default (val, precision) => {
    //清除“数字”和“.”以外的字符
    val = val.toString()
    precision ? val = val.replace(/[^\d.]/g, '') : val = val.replace(/[^\d]/g, '')

    //只保留第一个. 清除多余的
    val = val.replace(/\.{2,}/g, '.')
    val = val.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')

    //如果没有小数点，首位不能为类似于 01、02的金额
    if (val.indexOf('.') < 0 && val != '') {
        val = parseFloat(val)
    }

    return val
}