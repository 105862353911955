(function (a, b) {
    // 页面自适应脚本
    var c = a.documentElement,
        d = "orientationchange" in window ? "orientationchange" : "resize",
        e = window.recalc = function () {
            var a = c.clientWidth;
            if (a) {
                var fontsize = Math.floor(100 * (a / 1920));
                // 1680
                if (fontsize <= 87.5 && fontsize > 75) {
                    fontsize = 87.5
                }
                // 1440
                if (fontsize <= 75 && fontsize > 66.67) {
                    fontsize = 75;
                }
                //1280
                if (fontsize <= 66.67) {
                    fontsize = 66.67
                }
                if (fontsize >= 100) {
                    fontsize = 100;
                }
                c.style.fontSize = fontsize + "px"
            }
        };
    a.addEventListener && (b.addEventListener(d, e, !1), a.addEventListener("DOMContentLoaded", e, !1));
})(document, window)