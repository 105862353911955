// 路由meta的title项 必须为 该多语言对应字段名
import firstRouteOrderIcon from '@/assets/images/sidebar-icon/first-route-order-icon.svg'
import transferWarehouseOrderIcon from '@/assets/images/sidebar-icon/transfer-warehouse-order-icon.svg'
export default [
    {
        name: 'orderCenter',
        path: '/orderCenter',
        component: () => import('@/views/order-center'),
        children: [
            {
                name: 'firstRoute',
                path: '/orderCenterIndex',
                component: () => import('@/views/order-center/first-route'),
                redirect: '/orderCenter/firstRoute/index',
                meta: {
                    title: 'first_route_order',
                    icon: firstRouteOrderIcon,
                    isMulti: false,
                },
                children: [
                    {
                        name: 'firstRouteIndex',
                        path: '/orderCenter/firstRoute/index',
                        component: () => import('@/views/order-center/first-route/first-route-index'),
                        meta: {
                            title: 'first_route_order',
                            isShowing: true
                        }
                    },
                    {
                        name: 'firstRouteDetail',
                        path: '/orderCenter/firstRoute/detail',
                        component: () => import('@/views/order-center/first-route/first-route-detail'),
                        meta: {
                            title: 'order_detail',
                            isShowing: true
                        }
                    }
                ]
            },
            {
                name: 'transferWarehouse',
                path: '/orderCenter/transferWarehouse',
                component: () => import('@/views/order-center/transfer-warehouse'),
                redirect: '/orderCenter/transferWarehouse/index',
                meta: {
                    title: 'transfer_warehouse_order',
                    icon: transferWarehouseOrderIcon,
                    isMulti: false,
                },
                children: [
                    {
                        name: 'transferWarehouseIndex',
                        path: '/orderCenter/transferWarehouse/index',
                        component: () => import('@/views/order-center/transfer-warehouse/transfer-warehouse-index'),
                        meta: {
                            title: 'transfer_warehouse_order',
                            isShowing: true
                        }
                    },
                    {
                        name: 'transferWarehouseDetail',
                        path: '/orderCenter/transferWarehouse/detail',
                        component: () => import('@/views/order-center/transfer-warehouse/transfer-warehouse-detail'),
                        meta: {
                            title: 'order_detail',
                            isShowing: true
                        }
                    }
                ]
            },
        ]
    }
]