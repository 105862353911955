import enterpriseFrameworkIcon from "@/assets/images/enterprise-center/enterprise-framework-icon.svg"
import staffAccountIcon from "@/assets/images/enterprise-center/staff-account-icon.svg"
import roleAuthIcon from "@/assets/images/enterprise-center/role-auth-icon.svg"
/**
 * @param {Boolean} isMulti 是否有多级导航，仅父路由有
 * @param {Boolean} isShowing 是否显示  
 */
export default [
    {
        name: "enterpriseCenter",
        path: "/enterpriseCenter",
        component: () => import("@/views/enterprise-center"),
        children: [
            // 企业架构管理
            {
                name: "enterpriseFramework",
                path: "/enterpriseCenterIndex",
                component: () => import("@/views/enterprise-center/enterprise-framework"),
                redirect: "/enterpriseCenter/framework/systemSetting",
                meta: {
                    title: "Enterprise_architecture_management",
                    icon: enterpriseFrameworkIcon,
                    isMulti: true,
                    isShowing: true
                },
                children: [
                    {
                        name: "systemSetting",
                        path: "/enterpriseCenter/framework/systemSetting",
                        component: () => import("@/views/enterprise-center/enterprise-framework/system-setting"),
                        meta: {
                            title: "System_setting",
                            isShowing: true
                        }
                    },
                    {
                        name: "departmentSetting",
                        path: "/enterpriseCenter/framework/departmentSetting",
                        component: () => import("@/views/enterprise-center/enterprise-framework/department-setting"),
                        meta: {
                            title: "Department_setting",
                            isShowing: true
                        }
                    },
                ]
            },
            // 员工账号管理
            {
                name: "staffAccount",
                path: "/staffAccountIndex",
                component: () => import("@/views/enterprise-center/staff-account"),
                redirect: "/enterpriseCenter/staffAccount/index",
                meta: {
                    title: "Employee_account_management",
                    icon: staffAccountIcon,
                    isMulti: false,
                    isShowing: true
                },
                children: [
                    {
                        name: "staffAccountIndex",
                        path: "/enterpriseCenter/staffAccount/index",
                        component: () => import("@/views/enterprise-center/staff-account/staff-account-index"),
                        meta: {
                            title: "Employee_account_management",
                            isShowing: false
                        }
                    }
                ]
            },
            // 角色权限管理
            {
                name: "roleAuth",
                path: "/roleAuthIndex",
                component: () => import("@/views/enterprise-center/role-auth"),
                redirect: "/enterpriseCenter/roleAuth/index",
                meta: {
                    title: "Role_rights_management",
                    icon: roleAuthIcon,
                    isMulti: true,
                    isShowing: true
                },
                children: [
                    {
                        name: "roleAuthIndex",
                        path: "/enterpriseCenter/roleAuth/roleAuthIndex",
                        component: () => import("@/views/enterprise-center/role-auth/role-auth-index"),
                        meta: {
                            title: "Role_setting",
                            isShowing: true
                        }
                    },
                    {
                        name: "roleAuthClassification",
                        path: "/enterpriseCenter/roleAuth/roleAuthClassification",
                        component: () => import("@/views/enterprise-center/role-auth/role-auth-classification"),
                        meta: {
                            title: "Authority_management",
                            isShowing: true
                        }
                    },
                ]
            },
        ]
    }

]