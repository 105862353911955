import { computed, getCurrentInstance } from 'vue';
export default {
  setup(props, content) {
    const {
      proxy
    } = getCurrentInstance();
    const keyWord = computed({
      get() {
        return proxy.$attrs.value;
      },
      set(newVal) {
        const checked = proxy.$attrs.radioList.find(item => {
          return item.label === newVal;
        });
        content.emit('changeValue', checked);
      }
    });
    return {
      keyWord
    };
  }
};