import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-cb2d07ba"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "mask"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  class: "text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createBlock(_Teleport, {
    to: "html"
  }, [_createElementVNode("div", _hoisted_1, [_createVNode(_Transition, {
    name: "down"
  }, {
    default: _withCtx(() => [_withDirectives(_createElementVNode("div", {
      class: "message",
      style: _normalizeStyle($setup.style[$props.type])
    }, [_createElementVNode("img", {
      class: "icon",
      src: [$setup.style[$props.type].icon],
      alt: ""
    }, null, 8, _hoisted_2), _createElementVNode("span", _hoisted_3, _toDisplayString($props.text), 1)], 4), [[_vShow, $setup.visible]])]),
    _: 1
  })])]);
}