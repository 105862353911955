import "core-js/modules/es.array.push.js";
import { reactive, toRefs, getCurrentInstance } from "vue";
const __default__ = {
  setup(props, content) {
    const {
      proxy
    } = getCurrentInstance();
    const state = reactive({
      uploadPercent: "0",
      preUrl: "",
      preName: "",
      isPre: false,
      headers: {
        authKey: localStorage.getItem("authKey")
      },
      baseURL: process.env.VUE_APP_BASE_URL,
      fileList: proxy.$attrs['file-list'] || []
    });
    const handleRemove = file => {
      const uploadFiles = state.fileList;
      state.fileList = [];
      uploadFiles.map(item => {
        if (item.uid != file.uid) {
          state.fileList.push({
            ...item
          });
        }
      });
      content.emit("removeSuccess", state.fileList);
    };
    const handlePreview = file => {
      state.preName = file.name;
      state.preUrl = file.url || file.src;
      proxy.$attrs.listType === "picture" ? state.isPre = true : state.isPre = false;
      if (proxy.$attrs.listType === "text") {
        proxy.$downloadFile(file.response.data.ossSrc, file.name);
      }
    };
    const handleProgress = file => {
      state.uploadPercent = parseInt(file.percent) - 10 + '%';
      content.emit("isUploadSuccess", false);
    };
    const handleSuccess = (res, uploadFile, uploadFiles) => {
      if (res.code == 0) {
        state.fileList.push({
          name: uploadFile.name,
          src: res.data.src,
          uid: uploadFile.uid,
          url: res.data.ossSrc
        });
      } else {
        let index = uploadFiles.findIndex(item => item.uid == uploadFile.uid);
        uploadFiles.splice(index, 1);
        proxy.$TcMsg({
          type: "error",
          text: res.error
        });
      }
      content.emit("uploadSuccess", state.fileList);
      content.emit("isUploadSuccess", true);
    };
    const downloadPic = () => {
      proxy.$downloadFile(state.preUrl, state.preName);
    };
    return {
      ...toRefs(state),
      handlePreview,
      handleRemove,
      downloadPic,
      handleProgress,
      handleSuccess
    };
  }
};
import { useCssVars as _useCssVars } from 'vue';
const __injectCSSVars__ = () => {
  _useCssVars(_ctx => ({
    "41b51810": _ctx.uploadPercent
  }));
};
const __setup__ = __default__.setup;
__default__.setup = __setup__ ? (props, ctx) => {
  __injectCSSVars__();
  return __setup__(props, ctx);
} : __injectCSSVars__;
export default __default__;