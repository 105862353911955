const systemSetting = {
    namespaced: true,
    state: {
       systemLogo:""
    },
    getters: {
    },
    mutations: {
        setSystemLogo(state,data){
            state.systemLogo = data
        }
    },
    actions: {

    }
}

export default systemSetting