import { onMounted, reactive, toRefs, getCurrentInstance } from 'vue';
export default {
  props: {
    list: {
      type: Array
    }
  },
  setup(props) {
    const {
      proxy
    } = getCurrentInstance();
    const state = reactive({});
    onMounted(() => {});
    const downloadFile = item => {
      proxy.$downloadFile(item.request_url, item.name);
    };
    return {
      downloadFile,
      ...toRefs(state)
    };
  }
};