import { reactive, toRefs, onMounted, getCurrentInstance, watch, ref } from 'vue';
import { VueDraggable } from 'vue-draggable-plus';
export default {
  props: {
    pFieldSortList: {
      type: Array,
      default: () => []
    },
    pTableFields: {
      type: Array,
      default: () => []
    }
  },
  components: {
    VueDraggable
  },
  setup(props, content) {
    const {
      proxy
    } = getCurrentInstance();
    const state = reactive({
      isShowControlBox: false,
      fieldSortList: [],
      tableFields: []
    });
    const drag = ref();

    // 获取表格字段排序
    const getFieldSortList = () => {
      content.emit('getField');
    };

    // 字段上升
    const riseSort = (item, index) => {
      state.fieldSortList[index] = {
        ...state.fieldSortList[index - 1]
      };
      state.fieldSortList[index - 1] = {
        ...item
      };
    };

    // 字段下降
    const descendSort = (item, index) => {
      state.fieldSortList[index] = {
        ...state.fieldSortList[index + 1]
      };
      state.fieldSortList[index + 1] = {
        ...item
      };
    };

    // 保存字段排序
    const handleSaveField = () => {
      state.fieldSortList.map((item, index) => {
        item.is_hide = item.is_choose === true ? 1 : 2;
        item.sort = index + 1;
        delete item.is_choose;
      });
      content.emit('saveField', state.fieldSortList);
      state.isShowControlBox = false;
    };

    // 重置字段排序
    const handelResetField = () => {
      content.emit('resetField');
    };
    watch(() => props.pFieldSortList, val => {
      state.fieldSortList = JSON.parse(JSON.stringify(props.pFieldSortList));
    }, {
      deep: true
    });
    onMounted(() => {});
    return {
      ...toRefs(state),
      drag,
      getFieldSortList,
      riseSort,
      descendSort,
      handleSaveField,
      handelResetField
    };
  }
};